import React from 'react';
import PropTypes from 'prop-types';
import StatusBadge from 'app/components/common/StatusBadge';
import { Link } from 'react-router-dom';

const TableRow = ({ member }) => {
  return (
    <tr>
      <td className="text-nowrap">{member.name}</td>
      <td className="text-nowrap">
        <StatusBadge status={member.status} />
      </td>
      <td className="text-nowrap">
        <Link to="/dashboard/chat">message</Link>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  member: PropTypes.any
};

export default TableRow;
