import React from 'react';
import MailContent from 'app/pages/auth/verification/MailContent';

const ConfirmMail = () => (
  <div className="text-center">
    <MailContent email="xyz@abc.com" />
  </div>
);

export default ConfirmMail;
