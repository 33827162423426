import React from 'react';
import PasswordResetForm from './PasswordResetForm';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();

  return (
    <div className="text-center">
      {searchParams.get('hash') ? (
        <>
          <h5>Create new password</h5>
          <PasswordResetForm />
        </>
      ) : (
        <Alert variant="danger">The link is not valid.</Alert>
      )}
    </div>
  );
};

export default PasswordReset;
