import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { ContentsContext } from './context/CommitmentsContext';
import StillViewingModal from './slots/StillViewingModal';

const Controls = () => {
  const { isPlay, setIsPlay, isMuted, setIsMuted } =
    useContext(ContentsContext);
  const [modalShow, setModalShow] = useState(false);
  let timer;

  const handlePlay = () => {
    setIsPlay(!isPlay);
  };

  const handleMuted = () => {
    setIsMuted(!isMuted);
  };

  let runCountdown = () => {
    return setTimeout(() => {
      setIsPlay(false);
      setModalShow(true);
    }, 300 * 1000);
  };

  useEffect(() => {
    if (isPlay) {
      timer = runCountdown();
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPlay]);

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="p-1">
          <div className="d-flex justify-content-center justify-content-lg-end">
            <Button onClick={handlePlay} variant="link" size="lg">
              <FontAwesomeIcon
                icon={isPlay ? 'pause' : 'play'}
                className="fs-1"
              ></FontAwesomeIcon>
            </Button>
            &nbsp;
            <Button onClick={handleMuted} variant="link" size="lg">
              <FontAwesomeIcon
                icon={isMuted ? 'volume-mute' : 'volume-up'}
              ></FontAwesomeIcon>
            </Button>
          </div>
        </Card.Body>
      </Card>
      <StillViewingModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setIsPlay={setIsPlay}
      />
    </>
  );
};

export default Controls;
