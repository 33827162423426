import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import PropTypes from 'prop-types';

import UserContext from 'app/context/UserContext';
import AppContext from 'context/Context';
import ErrorLayout from 'app/layouts/ErrorLayout';
import AuthSimpleLayout from './AuthSimpleLayout';
import SimpleLogout from 'components/authentication/simple/Logout';
import Landing from 'app/public/pages/landing/Landing';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import DashboardLayout from 'app/layouts/DashboardLayout';
import MembershipLayout from 'app/layouts/MembershipLayout';
import Membership from 'app/pages/membership/Membership';
import Dashboard from 'app/pages/dashboard/Dashboard';
import TermsConditions from 'app/public/pages/terms-conditions/TermsConditions';
import ForgotPasswordPage from 'app/pages/auth/forgot-password/ForgotPasswordPage';
import ResetPasswordPage from 'app/pages/auth/reset-password/ResetPassword';
import Registration from 'app/pages/auth/registration/Registration';
import Login from 'app/pages/auth/login/Login';
import SettingsMember from 'app/pages/settings-member/SettingsMember';
import СonfirmMail from 'app/pages/auth/confirmation/СonfirmMail';
import Verification from 'app/pages/auth/verification/Verification';
import ReferralMember from 'app/pages/referral-member/ReferralMember';
import Commitments from 'app/pages/commitments/Commitments';
import PrivacyPolicy from 'app/public/pages/privacy-policy/PrivacyPolicy';
import Chat from 'app/pages/chat/Chat';
import Seminar from 'app/pages/seminar/Seminar';
import SeminarSections from 'app/pages/seminar/SeminarSections';
import SeminarList from 'app/pages/seminar/SeminarList';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  function RequireVerification({ children }) {
    switch (user?.status) {
      case 'inactive':
      case 'pending':
      case 'active':
        return children;
      default:
        return <Navigate to="/login" replace />;
    }
  }

  function RequireActivatoin({ children }) {
    return user?.status == 'active' ? (
      children
    ) : (
      <Navigate to="/membership" replace />
    );
  }

  return (
    <>
      <Routes>
        {/* Public **********************************************************/}

        <Route path="/" element={<Landing />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Auth ************************************************************/}

        <Route element={<AuthSimpleLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Registration />} />
          <Route path="logout" element={<SimpleLogout />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="confirm-mail" element={<СonfirmMail />} />
          <Route path="verification" element={<Verification />} />
        </Route>

        {/* Membershiop *****************************************************/}

        <Route element={<MembershipLayout />}>
          <Route
            path="/membership"
            element={
              <RequireVerification>
                <Membership />
              </RequireVerification>
            }
          />
        </Route>

        {/* Dasboard ********************************************************/}

        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={
              <RequireActivatoin>
                <Dashboard />
              </RequireActivatoin>
            }
          />
          <Route
            path="/dashboard/settings"
            element={
              <RequireActivatoin>
                <SettingsMember />
              </RequireActivatoin>
            }
          />
          <Route
            path="/dashboard/referrals"
            element={
              <RequireActivatoin>
                <ReferralMember />
              </RequireActivatoin>
            }
          />

          <Route
            path="/dashboard/commitments"
            element={
              <RequireActivatoin>
                <Commitments />
              </RequireActivatoin>
            }
          />

          <Route
            path="/dashboard/chat"
            element={
              <RequireActivatoin>
                <Chat />
              </RequireActivatoin>
            }
          />

          {/* Seminars ******************************************************/}

          <Route
            path="/dashboard/seminars"
            element={
              <RequireActivatoin>
                <SeminarList />
              </RequireActivatoin>
            }
          />

          <Route
            path="/dashboard/seminars/:seminarId"
            element={
              <RequireActivatoin>
                <SeminarSections />
              </RequireActivatoin>
            }
          />

          <Route
            path="/dashboard/seminars/:seminarId/sections/:sectionId"
            element={
              <RequireActivatoin>
                <Seminar />
              </RequireActivatoin>
            }
          />
        </Route>

        {/* Errors **********************************************************/}

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any
};

export default Layout;
