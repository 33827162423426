import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import useRequest from 'app/hooks/useRequest';
import { getCommitments } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import { SlotsContext } from '../context/CommitmentsContext';

const DailyViews = () => {
  const { data, sendData } = useRequest();
  const { user } = useContext(UserContext);
  const { counter } = useContext(SlotsContext);
  const [views, setViews] = useState([]);

  const updateData = () => {
    sendData(getCommitments(user.token, user.id, 0));
  };

  useEffect(() => {
    updateData();
  }, [counter]);

  useEffect(() => {
    if (data && data.length) {
      setViews(data);
    }
  }, [data]);

  return (
    <Card className="h-100">
      <Card.Body>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="mb-3 position-static"
        >
          <h6 className="mb-0 flex-1">Daily Views</h6>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          className="h-100 mt-n3"
        >
          <p className="display-1 text-900 my-4">{views.length}</p>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default DailyViews;
