import React from 'react';
import { Col, Card, Row, Button } from 'react-bootstrap';
import success from 'assets/img/icons/spot-illustrations/creating.png';
import { Link } from 'react-router-dom';

const Activated = () => {
  return (
    <Card className="pb-5">
      <Card.Body className="text-center py-5">
        <Row className="justify-content-center">
          <Col xs={12} md={7} className="mt-4 mb-3">
            <img className="img-fluid" src={success} alt="image" width="58%" />
          </Col>
        </Row>
        <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
          Your membership was successfully activated
        </h3>
        <p className="lead mb-3">
          <br className="d-none d-md-block" />
          Now, you are available to use the dashbord
        </p>
        <Link to="/dashboard">
          <Button variant="primary" className="me-2 mb-1">
            Go To Dasboard
          </Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Activated;
