import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import IconButton from 'components/common/IconButton';
import InvitePeopleModal from './InvitePeopleModal';
import axios from 'axios';
import { sendEmailReferral, getReferralCode } from 'app/api/MemberService';
import { toast } from 'react-toastify';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const InvitePeople = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { response, sendData } = useRequest();
  const [errors, setErrors] = useState({
    email: [],
    general: []
  });

  useEffect(() => {
    sendResponse();
  }, []);

  const referralCode = useMemo(() => {
    return response?.data ? response.data.referralCode : '';
  }, [response]);

  function sendResponse() {
    const config = getReferralCode(user.token, user.id);
    sendData(config);
  }

  const handleCopy = () => {
    setShowModal(true);
  };

  const handleSendEmail = e => {
    e.preventDefault();

    referralSendEmail();
  };

  const handlerChangeInput = e => {
    if (e.keyCode == 13 && email) {
      referralSendEmail();
    }
  };

  const referralSendEmail = () => {
    setLoading(true);
    setErrors({
      email: [],
      general: []
    });

    axios
      .request(sendEmailReferral(user.token, user.id, email))
      .then(() => {
        toast.success(`Send referral email for ${email}`, {
          theme: 'colored'
        });
        setEmail('');
      })
      .catch(error => {
        const errors = {
          email: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card>
        <Card.Body className="text-center py-5">
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img className="img-fluid" src={invite} alt="image" width="58%" />
            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Invite a friend, earn more tokens
          </h3>
          <p className="lead mb-5">
            Invite your friends and start working together in seconds.{' '}
            <br className="d-none d-md-block" /> Everyone you invite will
            receive a welcome email.
          </p>
          <Row className="justify-content-center">
            <Col md={7}>
              <Form as={Row} className="g-2">
                <Col sm>
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                    value={email}
                    onKeyUp={handlerChangeInput}
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    isInvalid={errors.email.length}
                  />
                  <FeedbackError errors={errors} type="email" />
                </Col>
                <Col sm="auto">
                  {!loading ? (
                    <Button
                      variant="primary"
                      className="d-block w-100"
                      type="submit"
                      disabled={!email}
                      onClick={handleSendEmail}
                    >
                      Send Invitation
                    </Button>
                  ) : (
                    <Button className="w-100" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </Button>
                  )}
                </Col>

                <Form.Group className="small mt-2">
                  <AlertError errors={errors} type="general" />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="bg-light text-center pt-4">
          <Row className="justify-content-center">
            <Col xs={11} sm={10}>
              <h4 className="fw-normal mb-0 fs-1 fs-md-2">
                More ways to <br className="d-sm-none" /> invite your friends
              </h4>

              <Row className="gx-2 my-4">
                <Col lg={4}></Col>
                <Col lg={4}>
                  <IconButton
                    className="d-block w-100 mb-2 mb-xl-0"
                    iconClassName="me-2"
                    variant="falcon-default"
                    icon="link"
                    onClick={handleCopy}
                  >
                    Copy Link
                  </IconButton>
                </Col>
                <Col lg={4}></Col>
              </Row>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <InvitePeopleModal
        referralCode={referralCode}
        show={showModal}
        setShow={setShowModal}
      />
    </>
  );
};

export default InvitePeople;
