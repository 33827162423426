import React from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';

import Footer from 'app/public/common/Footer';
import Hero from 'app/public/common/Hero';
import NavbarStandard from 'app/public/common/NavbarStandard';
import Section from 'app/public/common/Section';
import Content from './Content';

export default function TermsConditions() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | MineChain</title>
      </Helmet>
      <NavbarStandard />
      <Hero />
      <Section>
        <Card>
          <Card.Body>
            <Content />
          </Card.Body>
        </Card>
      </Section>
      <Footer />
    </>
  );
}
