import React, { useEffect, useState } from 'react';
import Section from 'components/common/Section';
import SectionHeader from '../../common/SectionHeader';
import illustration1 from '../../assets/images/landing/investment-join.png';
import illustration2 from '../../assets/images/landing/investment-earn.png';
import illustration3 from '../../assets/images/landing/investment-chain.png';
import InvestmentItem from './InvestmentItem';
import useRequest from 'app/hooks/useRequest';
import { getOption } from 'app/api/PublicOptionsService';

export default function Investment() {
  const { data, fetch } = useRequest();

  const [investment, setInvestment] = useState(null);
  const [sections, setSections] = useState([
    {
      icon: ['far', 'lightbulb'],
      iconText: 'PLAN',
      color: 'danger',
      title: 'JOIN',
      description:
        'with 3 levels of membership this network will fit your goals and around your schedule.',
      image: illustration1
    },
    {
      icon: ['far', 'object-ungroup'],
      iconText: 'BUILD',
      color: 'info',
      title: 'EARN',
      description:
        'Profit from joining a large network of users all with the same goal. Watch your balance increase for simply watching content.',
      image: illustration2,
      inverse: true
    },
    {
      icon: ['far', 'paper-plane'],
      iconText: 'DEPLOY',
      color: 'success',
      title: 'CHAIN',
      description:
        'Refer a friend, client or family members to build a chain. Grow our network and your balance.',
      image: illustration3
    }
  ]);

  useEffect(() => {
    fetch(getOption('landing_investment'));
  }, []);

  useEffect(() => {
    data && setInvestment(JSON.parse(data.optionValue));
  }, [data]);

  useEffect(() => {
    investment &&
      setSections(
        sections.map((s, i) => {
          return { ...s, ...investment.sections[i] };
        })
      );
  }, [investment]);

  return (
    <Section>
      <SectionHeader
        title={investment?.title || '...'}
        subtitle={investment?.subTitle || '...'}
      />
      {sections.map((s, idx) => (
        <InvestmentItem key={idx} {...s} />
      ))}
    </Section>
  );
}
