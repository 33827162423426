import React from 'react';
import { Helmet } from 'react-helmet';
import RegForm from './RegistrationForm';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Registration() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Register | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">Register</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600 mb-0">
            Have an account? <Link to="/login">Login</Link>
          </p>
        </Col>
      </Row>
      <RegForm />
    </>
  );
}
