import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { verification } from 'app/api/MemberService';

const ConfirmMailContent = ({ titleTag: TitleTag }) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState();

  useEffect(() => {
    let hash = searchParams.get('hash');
    if (hash) {
      sendVerification(hash);
    } else {
      setText('Sorry link is not enable');
      setLoading(false);
    }
  }, [searchParams]);

  async function sendVerification(hash) {
    axios
      .request(verification(hash))
      .then(response => {
        let email = response.data.email;
        setText(`Email ${email} verified successfully`);
        setLoading(false);
      })
      .catch(() => {
        setText('Sorry link is not enable');
        setLoading(false);
      });
  }

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>Verification</TitleTag>

      {loading ? (
        <p>
          <Spinner as="span" animation="border" role="status"></Spinner>
        </p>
      ) : (
        <p>{text}</p>
      )}

      <Button as={Link} color="primary" size="sm" className="mt-3" to="/login">
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to login
      </Button>
    </>
  );
};

ConfirmMailContent.propTypes = {
  email: PropTypes.string.isRequired,
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
