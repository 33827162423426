import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Col, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

import { ContentsContext, SlotsContext } from '../context/CommitmentsContext';
import Image from './Image';
import Video from './Video';

const Slot = ({ slot }) => {
  const { setCurrent, watched } = useContext(SlotsContext);
  const { setIsPlay, keepIsPlay } = useContext(ContentsContext);

  const handleWatched = () => {
    watched(slot);
  };

  const handleClick = () => {
    keepIsPlay();
    setIsPlay(false);
    setCurrent(slot);
  };

  const slotCSS = {
    paddingLeft: '8px',
    paddingRight: '8px',
    marginTop: '0px',
    marginBottom: '16px'
  };

  const play = type => {
    if (type === 'video') {
      return <Video content={slot.data} handleWatched={handleWatched} />;
    } else {
      return <Image content={slot.data} handleWatched={handleWatched} />;
    }
  };

  return (
    <>
      <Col xs="6" sm="4" style={slotCSS}>
        <div onClick={handleClick} className="slot ratio ratio-16x9 bg-200">
          {slot.data ? (
            <>{play(slot.data.type)}</>
          ) : (
            <Flex justifyContent="center" alignItems="center">
              <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Flex>
          )}
        </div>
      </Col>
    </>
  );
};

Slot.propTypes = {
  slot: PropTypes.object
};

export default Slot;
