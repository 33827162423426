import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const ViewerStillViewing = ({ confirm }) => {
  return (
    <div className="text-center">
      <p className="fw-semi-bold fs-1">
        Are you still viewing
        <br /> this page?
      </p>
      <Button variant="outline-primary" onClick={confirm} className="px-4 mt-2">
        Yes
      </Button>
    </div>
  );
};

ViewerStillViewing.propTypes = {
  content: PropTypes.object,
  controls: PropTypes.object,
  confirm: PropTypes.func
};

export default ViewerStillViewing;
