import React from 'react';
import { Card, Image } from 'react-bootstrap';

const ImagePlayer = ({ media }) => {
  return (
    <Card className="overflow-hidden">
      <Card.Header>{media.title}</Card.Header>
      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <Image
          src={media.path}
          rounded
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
        />
      </Card.Body>
    </Card>
  );
};

export default ImagePlayer;
