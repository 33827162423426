import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Modal, Overlay, Tooltip } from 'react-bootstrap';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { getReferralCode } from 'app/api/MemberService';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InvitePeopleModal = ({ show, setShow }) => {
  const copyRef = useRef(null);
  const { response, sendData } = useRequest();
  const { user } = useContext(UserContext);
  const clipboard = {
    value: '',
    copied: false
  };
  const target = useRef(null);
  const [showToogle, setShowToogle] = useState(false);

  useEffect(() => {
    sendResponse();
  }, []);

  const referralCode = useMemo(() => {
    return response?.data ? response.data.referralCode : '';
  }, [response]);

  const copyLinkText = useMemo(() => {
    return referralCode
      ? `${window.location.origin}/register?referral-id=${referralCode}`
      : '';
  }, [referralCode]);

  function sendResponse() {
    const config = getReferralCode(user.token, user.id);
    sendData(config);
  }

  useEffect(() => {
    if (show) {
      copyRef.current.select();
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="overflow-hidden"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5" id="copyLinkModalLabel">
          Your personal referral link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light p-4">
        <Form className="row g-3 d-flex justify-content-between">
          <Col xs={11}>
            <Form.Control
              size="sm"
              type="text"
              className="invitation-link"
              defaultValue={copyLinkText}
              ref={copyRef}
              disabled={true}
              readOnly={true}
            />
          </Col>
          <Col xs={1}>
            <span
              className="d-inline-block"
              ref={target}
              onClick={() => setShowToogle(!showToogle)}
            >
              <CopyToClipboard
                style={{ position: 'inline-block' }}
                onCopy={() => {
                  clipboard.copied;
                }}
                text={copyLinkText}
              >
                <FontAwesomeIcon icon="copy" className="fs-1"></FontAwesomeIcon>
              </CopyToClipboard>
              <Overlay
                target={target.current}
                show={showToogle}
                placement="top"
              >
                {props => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
            </span>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

InvitePeopleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  referralCode: PropTypes.string
};

export default InvitePeopleModal;
