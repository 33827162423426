import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FeedbackError = ({ errors, type, className }) => {
  return (
    <Form.Control.Feedback type="invalid" className={className} key={type}>
      <ul className="list-unstyled mb-0">
        {errors[type].map(item => (
          <li key={item.msg}>{item.msg}</li>
        ))}
      </ul>
    </Form.Control.Feedback>
  );
};

FeedbackError.propTypes = {
  errors: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string
};

export default FeedbackError;
