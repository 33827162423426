import React, { useContext, useEffect, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';

import { getTransactions } from 'app/api/MemberService';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import WeeklyRewardsChart from './WeeklyRewardsChart';
import WeeklyRewardsSum from './WeeklyRewardsSum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardContext from '../../contexts/DashboardContext';

const WeeklyRewards = () => {
  const { setTransactions: setTransactionsToContext } =
    useContext(DashboardContext);

  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const [transations, setTransations] = useState([]);

  useEffect(() => {
    if (response) return null;

    sendData(getTransactions(user.token, user.id));
  }, []);

  useEffect(() => {
    if (!response) return null;

    setTransations(response.data);
    setTransactionsToContext(response.data);
  }, [response]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Weekly Rewards
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Your progress this week compared to the previous week.
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>
      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <WeeklyRewardsSum transations={transations} />
        <WeeklyRewardsChart transations={transations} />
      </Card.Body>
    </Card>
  );
};

WeeklyRewards.propTypes = {};

export default WeeklyRewards;
