import React from 'react';
import PropTypes from 'prop-types';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { getFirstDayOfWeek } from 'app/helpers/date';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const dateToString = date => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

const getOptions = data => {
  const barsData = data.map(item => {
    return {
      value: item.total
    };
  });

  return {
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      formatter: '{b0} : {c0}',
      transitionDuration: 0,
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      boundaryGap: false,
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    series: [
      {
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          borderRadius: 10
        },
        barWidth: '5px',
        itemStyle: {
          borderRadius: 10,
          color: getColor('primary')
        },
        data: barsData,
        z: 10
      }
    ],
    grid: { right: 5, left: 10, top: 0, bottom: 0 }
  };
};

const WeeklyCommitmentsChart = ({ commitments }) => {
  const monday = getFirstDayOfWeek(new Date());

  let chartData = [];
  for (var j = 0; j <= 6; j++) {
    let tomorrow = new Date(monday);
    tomorrow.setDate(tomorrow.getDate() + j);

    chartData.push({
      date: dateToString(tomorrow),
      total: 0
    });
  }

  chartData = chartData.map(chartItem => {
    let total = commitments.reduce((acc, commitment) => {
      let commitmentDate = dateToString(new Date(commitment.createdAt));

      if (chartItem.date === commitmentDate) {
        acc += 1;
      }

      return acc;
    }, 0);

    return { ...chartItem, total: total };
  });

  return (
    <BasicECharts
      echarts={echarts}
      options={getOptions(chartData)}
      style={{ width: '8rem', height: 87 }}
    />
  );
};

WeeklyCommitmentsChart.propTypes = {
  commitments: PropTypes.array.isRequired
};

export default WeeklyCommitmentsChart;
