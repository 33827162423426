import React from 'react';
import PropTypes from 'prop-types';
import Section from 'components/common/Section';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Section bg="dark" className="pt-5 pb-3 light">
        <div
          className="position-absolute btn-back-to-top cursor-pointer bg-dark"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <Row>
          <Col>
            <Row className="mt-0 mb-3">
              <Col xs={6} md={3}>
                <ul className="list-unstyled mt-2">
                  <li className="mb-2">
                    <Link className="text-600" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link className="text-600" to="/terms-and-conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-between fs--1 mt-2 mb-2">
          <Col sm="auto">
            <p className="mb-0 text-600">
              Minechain {new Date().getFullYear()} &copy;{' '}
            </p>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Footer;
