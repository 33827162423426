import React from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumb, Button, Card, Col, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import PageHeader from 'components/common/PageHeader';
import FalconCardHeader from 'components/common/FalconCardHeader';

import useApi from 'app/hooks/useApi';
import { viewSeminar, viewSeminarSections } from 'app/api/SeminarConfig';

const SectionCard = ({ seminar, section }) => {
  return (
    <Col lg={4} className="mt-3">
      <Card className="h-100">
        <Image
          src={section.bannerImageFullPath}
          rounded
          style={{ height: '15rem', objectFit: 'cover' }}
        />
        <Card.Body>
          <Card.Title as="h5">{section.title}</Card.Title>
          <Card.Text>{section.description}</Card.Text>
          <Link to={`/dashboard/seminars/${seminar.id}/sections/${section.id}`}>
            <Button color="primary" size="sm">
              View
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </Col>
  );
};

const SeminarSections = () => {
  const { seminarId } = useParams();
  const { data: seminar } = useApi(viewSeminar(seminarId));
  const { data: sections } = useApi(viewSeminarSections(seminarId));

  if (!seminar || !sections) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{seminar.title}</title>
      </Helmet>

      <PageHeader title={seminar.title} description="" className="mb-3">
        <Breadcrumb className="mt-1">
          <Breadcrumb.Item href="">
            <Link to="/dashboard/seminars">Seminars</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{seminar.title}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Card className="overflow-hidden">
        <FalconCardHeader title={seminar.titleBlock} titleTag="h6" light />
        <Card.Body>
          <p className="m-0">{seminar.descriptionBlock}</p>
        </Card.Body>
      </Card>

      <Row>
        {sections.map(section => (
          <SectionCard key={section.id} seminar={seminar} section={section} />
        ))}
      </Row>
    </>
  );
};

export default SeminarSections;
