import React, { useMemo, useEffect, useContext } from 'react';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { getReferrals } from 'app/api/MemberService';
import { Card, Row, Table, Col } from 'react-bootstrap';
import Thead from './thead/Thead';
import TableRow from './table-row/TableRow';

export default function RefarralsTable() {
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const columns = ['Username', 'Status', 'Chat'];

  useEffect(() => {
    sendResponse();
  }, []);

  const referrals = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  function sendResponse() {
    const config = getReferrals(user.token, user.id);
    sendData(config);
  }

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <Card>
          <Card.Header>
            <h5>Referrals</h5>
          </Card.Header>
          <Card.Body className="fs--1 fs-lg-0">
            <Table responsive striped hover>
              {referrals.length ? <Thead columns={columns} /> : ''}
              <tbody>
                {referrals.length ? (
                  referrals.map(item => (
                    <TableRow key={item.id} member={item} />
                  ))
                ) : (
                  <tr>
                    No referrals yet. Invite your friends to join and earn more
                    tokens!
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
