import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LinkPlayer = ({ media }) => {
  const clipboard = {
    value: '',
    copied: false
  };

  return (
    <Card className="overflow-hidden">
      <Card.Header className="">
        <Flex justifyContent="between">
          <span className="fw-semibold">{media.title}</span>
          <div className="header-btn">
            <CopyToClipboard
              onCopy={() => {
                clipboard.copied;
              }}
              text={media.path}
            >
              <FontAwesomeIcon icon="copy" className="fs-2"></FontAwesomeIcon>
            </CopyToClipboard>
            <a
              href={media.path}
              target="_blank"
              rel="noopener noreferrer"
              className="ms-4"
            >
              <FontAwesomeIcon
                icon="external-link-alt"
                className="fs-2"
              ></FontAwesomeIcon>
            </a>
          </div>
        </Flex>
      </Card.Header>

      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <Flex justifyContent="center" alignItems="center">
          <a href={media.path} target="_blank" rel="noopener noreferrer">
            Please follow the external link{' '}
            <FontAwesomeIcon
              icon="external-link-alt"
              className="fs-1"
            ></FontAwesomeIcon>
          </a>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default LinkPlayer;
