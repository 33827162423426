import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { sendEmailReferral } from 'app/api/MemberService';
import axios from 'axios';
import UserContext from 'app/context/UserContext';
import FeedbackError from 'app/components/common/FeedbackError';

const InvitationForm = () => {
  const { user } = useContext(UserContext);

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: [],
    general: []
  });

  const handlerChangeInput = e => {
    if (e.keyCode == 13 && email) {
      referralSendEmail();
    }
  };

  const handleSendBtn = e => {
    e.preventDefault();
    referralSendEmail();
  };

  const referralSendEmail = () => {
    setErrors({
      email: [],
      general: []
    });
    setIsLoading(true);

    axios
      .request(sendEmailReferral(user.token, user.id, email))
      .then(() => {
        toast.success(`Send referral email to ${email}`, {
          theme: 'colored'
        });
        setEmail('');
      })
      .catch(error => {
        const errors = {
          email: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form>
      <Row>
        <Col className="col-9">
          <Form.Label>Send invitation by email address:</Form.Label>
          <Form.Control
            onKeyUp={handlerChangeInput}
            onChange={e => setEmail(e.target.value)}
            value={email}
            type="text"
            placeholder="example@email.com"
            disabled={isLoading}
            isInvalid={errors.email.length}
          />
          <FeedbackError errors={errors} type="email" />
        </Col>
        <Col className="col-3">
          <Button
            onClick={handleSendBtn}
            type="submit"
            variant="primary"
            style={{ marginTop: '31px', width: '100%' }}
            disabled={isLoading || !email}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Send'
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InvitationForm;
