import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Col,
  Row,
  Spinner,
  Modal,
  CloseButton,
  Form,
  Button,
  Overlay,
  Tooltip
} from 'react-bootstrap';
import Qr from 'app/assets/img/qr/qr-code.png';
import { subscription } from 'app/api/MemberService';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import Tether from 'app/assets/img/coins/tether-usdt-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'react-copy-to-clipboard';
import UserContext from 'app/context/UserContext';
import PropTypes from 'prop-types';
import AlertError from 'app/components/common/AlertError';
import useRequest from 'app/hooks/useRequest';
import { getOption } from 'app/api/OptionsService';

const StripeBuyButton = ({ subscription }) => {
  if (subscription.slug === 'bronze') {
    return (
      <stripe-buy-button
        buy-button-id="buy_btn_1Mpb5JGInLZS2KPaYLS4MIr2"
        publishable-key="pk_live_51MUwzWGInLZS2KPa7L51idF92uBCeRxMVcJYpb0aTyY75rd5hOW9jsdggO30iAfCPbG0AlPSvim8NdiEmktRxgvp00707hzaYa"
      ></stripe-buy-button>
    );
  }

  if (subscription.slug === 'silver') {
    return (
      <stripe-buy-button
        buy-button-id="buy_btn_1MpfC7GInLZS2KPaBdCkVh7j"
        publishable-key="pk_live_51MUwzWGInLZS2KPa7L51idF92uBCeRxMVcJYpb0aTyY75rd5hOW9jsdggO30iAfCPbG0AlPSvim8NdiEmktRxgvp00707hzaYa"
      ></stripe-buy-button>
    );
  }

  if (subscription.slug === 'gold') {
    return (
      <stripe-buy-button
        buy-button-id="buy_btn_1N7CGsGInLZS2KPaJ7sSye5h"
        publishable-key="pk_live_51MUwzWGInLZS2KPa7L51idF92uBCeRxMVcJYpb0aTyY75rd5hOW9jsdggO30iAfCPbG0AlPSvim8NdiEmktRxgvp00707hzaYa"
      ></stripe-buy-button>
    );
  }

  return <></>;
};

const MLModal = ({ showModal, handlerShowModal, selectedSubscription }) => {
  const { user, setStatus } = useContext(UserContext);

  const [info, setInfo] = useState({});
  const [formData, setFormData] = useState({
    receipt: '',
    startedAt: ''
  });

  const [errors, setErrors] = useState({
    receipt: [],
    startedAt: []
  });

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const target = useRef(null);

  const clipboard = {
    value: '',
    copied: false
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const { data, fetch } = useRequest();

  function handlerSendSubscription() {
    setLoading(true);
    setErrors({
      receipt: [],
      startedAt: []
    });

    axios
      .request(
        subscription(user.token, {
          level: selectedSubscription.slug,
          receipt: formData.receipt,
          amount: selectedSubscription.price,
          startedAt: new Date(
            Date.UTC(
              formData.startedAt.getFullYear(),
              formData.startedAt.getMonth(),
              formData.startedAt.getDate()
            )
          ).toString()
        })
      )
      .then(() => {
        setStatus('pending');
        handlerShowModal();
      })
      .catch(error => {
        const errors = {
          receipt: [],
          startedAt: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetch(getOption(user, 'membership_common'));
  }, []);

  useEffect(() => {
    if (!data) return null;
    setInfo(JSON.parse(data.optionValue));
  }, [data]);

  return (
    <Modal
      show={showModal}
      onHide={() => handlerShowModal()}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedSubscription.title}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handlerShowModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-lg-3">
          <Col lg={6} className="text-center">
            <h3 className="pb-3">Payment Method #1</h3>
            <StripeBuyButton subscription={selectedSubscription} />
          </Col>
          <Col
            lg={6}
            className="d-flex flex-column flex-column justify-content-between mt-5 mt-lg-0"
          >
            <div className="d-flex flex-column align-items-center px-5">
              <h3 className="pb-3">Payment Method #2</h3>
              <h4>
                Send {selectedSubscription.price}{' '}
                <span style={{ color: '#50AF95', margin: '0 5px 0 0' }}>
                  USDT
                </span>
                <img
                  src={Tether}
                  width="22px"
                  style={{ margin: '-4px 0 0 0' }}
                />
              </h4>
              <p className="text-800 mb-0">From Your Crypto Wallet To:</p>
              <img src={info?.qr || Qr} width="200" className="my-2" />
              <p className="text-600 fs--2 w-lg-50">
                Send only USDT to this deposit address. This address does not
                support deposit of non-fungible token.
              </p>
            </div>

            <div className="my-3"></div>

            <p>
              Send To Wallet Address
              <span className="d-block">
                <span className="fw-bold text-break text-primary">
                  {info.wallet}
                </span>
                <span
                  className="d-inline-block"
                  ref={target}
                  onClick={() => setShow(!show)}
                >
                  <CopyToClipboard
                    style={{ position: 'relative', marginLeft: '10px' }}
                    onCopy={() => {
                      clipboard.copied;
                    }}
                    text={info.wallet}
                  >
                    <FontAwesomeIcon
                      icon="copy"
                      className="fs-1"
                    ></FontAwesomeIcon>
                  </CopyToClipboard>
                  <Overlay target={target.current} show={show} placement="top">
                    {props => (
                      <Tooltip id="overlay-example" {...props}>
                        Copied!
                      </Tooltip>
                    )}
                  </Overlay>
                </span>
              </span>
            </p>
            <p>
              Send Using Network
              <span className="fw-bold d-block text-primary">
                {info.network}
              </span>
            </p>
            <Form onSubmit={() => {}} className="p-3 bg-light rounded-2">
              <Form.Group className="mb-3">
                <Form.Label>What date did you send:</Form.Label>
                <DatePicker
                  selected={formData.startedAt}
                  onChange={date =>
                    setFormData({
                      ...formData,
                      startedAt: date
                    })
                  }
                  formatWeekDay={day => day.slice(0, 3)}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  placeholderText="Select Date"
                />
              </Form.Group>

              <div className="mb-3 mt-3">
                <AlertError errors={errors} type="startedAt" />
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Internal Transfer ID Number (Txid):</Form.Label>
                <Form.Control
                  value={formData.receipt}
                  name="receipt"
                  onChange={handleFieldChange}
                  placeholder="Enter transaction number"
                  type="text"
                />
              </Form.Group>

              <div className="mb-3 mt-3">
                <AlertError errors={errors} type="receipt" />
              </div>

              {!loading ? (
                <Row>
                  <Col>
                    <Button
                      onClick={() => {
                        handlerSendSubscription();
                      }}
                      color="primary"
                      disabled={!formData.receipt || !formData.startedAt}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      onClick={() => handlerShowModal()}
                      variant="outline-primary"
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span>Loading...</span>
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

MLModal.propTypes = {
  showModal: PropTypes.bool,
  handlerShowModal: PropTypes.func,
  selectedSubscription: PropTypes.object
};

export default MLModal;
