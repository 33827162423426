import React, { useContext } from 'react';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Modal } from 'react-bootstrap';
import ReferalLinkForm from './ReferalLinkForm';
import Divider from 'components/common/Divider';
import InvitationForm from './InvitationForm';
import ModalReferralContext from '../../contexts/ModalReferralContext';

const EarnModal = () => {
  const { show, handleClose } = useContext(ModalReferralContext);

  return (
    <>
      <Modal size="lg" show={show} keyboard={true} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Earn Tokens</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ReferalLinkForm />
          <Divider className="mt-3 mb-3">OR</Divider>
          <InvitationForm />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EarnModal;
