import { createContext } from 'react';

const ModalReferralContext = createContext({
  show: null,
  toogle: null,
  handleClose: null,
  handleShow: null
});

export default ModalReferralContext;
