import React, { useMemo, useContext, useEffect, useState } from 'react';
import { getMembershipLevels } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { Col, Row, Spinner, Card } from 'react-bootstrap';
import MembershipLevelCard from './Card';
import MLModal from './Modal';
import FAQ from './FAQ';
import PageHeader from 'components/common/PageHeader';

export default function MembershipLevelCards() {
  const { user } = useContext(UserContext);
  const { response, sendData, loading } = useRequest();
  const [modalShow, setModalShow] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  let levels = [];
  let isSroll = false;

  useEffect(() => {
    sendResponse();

    return () => {};
  }, []);

  levels = useMemo(() => {
    return response?.data ? response.data : [];
  }, [response]);

  isSroll = useMemo(() => {
    const needle = levels.find(l => {
      return l.available === false;
    });

    return needle ? true : false;
  }, [levels]);

  function sendResponse() {
    const config = getMembershipLevels(user.token, user.id);
    sendData(config);
  }

  function handlerTogleModalShow(subscription = {}) {
    const isShow = !modalShow;

    if (isShow) {
      setSelectedSubscription(subscription);
    } else {
      setSelectedSubscription({});
    }

    setModalShow(isShow);
  }

  return (
    <>
      <PageHeader
        title="Activate Your Account"
        description="Select a Membership Level"
        className="mb-3"
        titleTag="h2"
      ></PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            {loading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              levels.map(level => (
                <MembershipLevelCard
                  isSroll={isSroll}
                  handlerShowModal={handlerTogleModalShow}
                  key={level.id}
                  level={level}
                />
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
      <FAQ />
      <MLModal
        showModal={modalShow}
        selectedSubscription={selectedSubscription}
        handlerShowModal={handlerTogleModalShow}
      />
    </>
  );
}
