import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image as BootstrapImage } from 'react-bootstrap';
import { ContentsContext } from '../context/CommitmentsContext';

const Image = ({ content, handleWatched }) => {
  const { isPlay } = useContext(ContentsContext);
  let timer;

  useEffect(() => {
    timer = 0;
    const interval = setInterval(() => {
      if (isPlay) {
        timer += 1;

        if (timer >= 10) {
          handleWatched();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [content, isPlay]);

  return (
    <>
      <BootstrapImage
        className="commitments-img"
        src={content.filename}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </>
  );
};

Image.propTypes = {
  content: PropTypes.object,
  handleWatched: PropTypes.func
};

export default Image;
