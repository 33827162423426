import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import useUser from './hooks/useUser';
import Layout from 'app/layouts/Layout';
import UserContext from './context/UserContext';
import 'app/helpers/initFA';

const App = () => {
  const { user, addUser, removeUser, setStatus } = useUser();

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <UserContext.Provider
        value={{
          user: user,
          setUser: addUser,
          removeUser: removeUser,
          setStatus: setStatus
        }}
      >
        <Layout />
      </UserContext.Provider>
    </Router>
  );
};

export default App;
