import React from 'react';
import { Col, Card, Row } from 'react-bootstrap';
import checking from 'assets/img/icons/spot-illustrations/1.svg';

const Pending = () => {
  return (
    <Card>
      <Card.Body className="text-center py-5">
        <Row className="justify-content-center">
          <Col xs={12} md={7} className="mt-4 mb-3">
            <img className="img-fluid" src={checking} alt="image" width="58%" />
          </Col>
        </Row>
        <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
          Your application is being processed
        </h3>
        <p className="lead mb-5">
          <br className="d-none d-md-block" />
          Please, wait for membership activation
        </p>
      </Card.Body>
    </Card>
  );
};

export default Pending;
