import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import CountUp from 'react-countup';

import Flex from 'components/common/Flex';
import Notification from './Notification.jsx';
import Background from 'components/common/Background';
import ecomBg from 'assets/img/illustrations/ecommerce-bg.png';

import DashboardContext from '../../contexts/DashboardContext';
import { notifications } from 'data/dashboard/ecom';
import useReferralRequest from '../../hooks/useReferralRequest';

const today = new Date();
today.setHours(0, 0, 0, 0);

const TodaysAlerts = () => {
  const { commitments, transactions } = useContext(DashboardContext);
  const { referrals } = useReferralRequest();

  const [alerts, setAlerts] = useState([
    {
      id: 1,
      slug: 'commitments',
      visible: false,
      title:
        '<strong>Commitments incomplete.</strong> Requires at least 12 views.',
      linkPath: '/dashboard/commitments',
      linkTitle: 'Commitments',
      type: 'warning'
    },
    {
      id: 2,
      slug: 'referrals',
      visible: false,
      title:
        '<strong>Referrals incomplete.</strong> Requires at least 3 active referrals.',
      linkPath: '/dashboard/referrals',
      linkTitle: 'Referrals',
      type: 'warning'
    }
  ]);

  const [dailyCommitments, setDailyCommitments] = useState(0);
  const [dailyReferrals, setDailyReferrals] = useState(0);
  const [dailyRewards, setDailyRewards] = useState(0);

  useEffect(() => {
    setDailyRewards(
      transactions
        .filter(t => {
          let trnDate = new Date(t.createdAt);
          return trnDate > today;
        })
        .reduce((acc, t) => {
          return (acc += t.amount);
        }, 0)
    );
  }, [transactions]);

  useEffect(() => {
    setDailyCommitments(
      commitments.filter(c => {
        let commDate = new Date(c.createdAt);
        return commDate > today;
      }).length
    );
  }, [commitments]);

  useEffect(() => {
    setDailyReferrals(
      referrals.reduce((acc, r) => {
        if (r.status === 'active') {
          acc += 1;
        }

        return acc;
      }, 0)
    );
  }, [referrals]);

  useEffect(() => {
    setAlerts(
      alerts.map(a => {
        if (a.slug === 'commitments') {
          a.visible = dailyCommitments <= 11;
        }

        return { ...a };
      })
    );
  }, [dailyCommitments]);

  useEffect(() => {
    setAlerts(
      alerts.map(r => {
        if (r.slug === 'referrals') {
          r.visible = dailyReferrals <= 2;
        }

        return { ...r };
      })
    );
  }, [dailyReferrals]);

  return (
    <Card className="bg-transparent-50 overflow-hidden">
      <Card.Header className="position-relative">
        <Background
          image={ecomBg}
          className="d-none d-md-block bg-card z-index-1"
          style={{
            backgroundSize: '230px',
            backgroundPosition: 'right bottom',
            zIndex: '-1'
          }}
        />
        <div className="position-relative z-index-2">
          <div>
            <p>Here’s what happening today</p>
          </div>
          <Flex className="py-3">
            <div className="pe-3">
              <p className="text-600 fs--1 fw-medium">Today's Rewards</p>
              <h4 className="text-800 mb-0">
                <CountUp end={dailyRewards} duration={2} separator="," />
              </h4>
            </div>
            <div className="ps-3">
              <p className="text-600 fs--1 fw-medium">Today’s Commitments</p>
              <h4 className="text-800 mb-0">
                <CountUp end={dailyCommitments} duration={2} separator="," />
              </h4>
            </div>
          </Flex>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <ul className="mb-0 list-unstyled">
          {alerts.map(
            (alert, idx) =>
              alert.visible && (
                <Notification
                  key={alert.id}
                  notification={alert}
                  isLast={notifications.length - 1 === idx}
                />
              )
          )}
        </ul>
      </Card.Body>
    </Card>
  );
};

TodaysAlerts.propTypes = {};

export default TodaysAlerts;
