import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';

const dateToString = date => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

function getFirstDayOfWeek(d) {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(date.setDate(diff));
}

function getFirstDayOfPreviousWeek(d) {
  const bufDate = new Date(d);
  const date = new Date(bufDate.setDate(bufDate.getDate() - 7));
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(date.setDate(diff));
}

const today = new Date();

const calcPercent = (currentWeekSum, previousWeekSum) => {
  return ((currentWeekSum * 100) / previousWeekSum - 100).toFixed(1);
};

const WeeklyCommitmentsSum = ({ commitments }) => {
  const currentMonday = getFirstDayOfWeek(today);
  let currentWeek = [];
  for (var j = 0; j <= 6; j++) {
    let tomorrow = new Date(currentMonday);
    tomorrow.setDate(tomorrow.getDate() + j);

    currentWeek.push({
      date: dateToString(tomorrow),
      dateObj: tomorrow,
      total: 0
    });
  }

  currentWeek = currentWeek.map(chartItem => {
    let total = commitments.reduce((acc, commitment) => {
      let commitmentDate = dateToString(new Date(commitment.createdAt));

      if (chartItem.date === commitmentDate) {
        acc += 1;
      }

      return acc;
    }, 0);

    return { ...chartItem, total: total };
  });

  const currentWeekSum = currentWeek.reduce((acc, item) => {
    return (acc += item.total);
  }, 0);

  //////////////////////////////////////////////////////////

  const previousMonday = getFirstDayOfPreviousWeek(today);

  let previousWeek = [];
  for (var i = 0; i <= 6; i++) {
    let tomorrow = new Date(previousMonday);
    tomorrow.setDate(tomorrow.getDate() + i);

    previousWeek.push({
      date: dateToString(tomorrow),
      dateObj: tomorrow,
      total: 0
    });
  }

  previousWeek = previousWeek.map(chartItem => {
    let total = commitments.reduce((acc, commitment) => {
      let commitmentDate = dateToString(new Date(commitment.createdAt));

      if (chartItem.date === commitmentDate) {
        acc += 1;
      }

      return acc;
    }, 0);

    return { ...chartItem, total: total };
  });

  const previousWeekSum = previousWeek.reduce((acc, item) => {
    return (acc += item.total);
  }, 0);

  ///////////////////////////////////////////////////////////

  const renderBadge = percent => {
    return percent === null ? (
      <SoftBadge pill bg="info" className="me-2">
        &mdash;
      </SoftBadge>
    ) : (
      <SoftBadge
        pill
        bg={classNames(percent > 0 ? 'success' : 'info')}
        className="me-2"
      >
        <FontAwesomeIcon
          icon={classNames(percent > 0 ? 'caret-up' : 'caret-down')}
          className="me-1"
        />
        {Math.abs(percent)}%
      </SoftBadge>
    );
  };

  ///////////////////////////////////////////////////////////

  const percent =
    previousWeekSum !== 0 ? calcPercent(currentWeekSum, previousWeekSum) : null;

  return (
    <div>
      <h2 className="fw-normal text-700 mb-1 lh-1">
        <CountUp end={currentWeekSum} duration={2} separator="," />
      </h2>
      {renderBadge(percent)}
      {currentWeekSum >= 12 ? (
        <SoftBadge pill bg="success" className="me-2">
          complete
        </SoftBadge>
      ) : (
        <SoftBadge pill bg="danger" className="me-2">
          incomplete
        </SoftBadge>
      )}
    </div>
  );
};

WeeklyCommitmentsSum.propTypes = {
  commitments: PropTypes.array.isRequired
};

export default WeeklyCommitmentsSum;
