import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';

import ReferralRequestContext from '../../contexts/ReferralRequestContext';
import ModalReferralContext from '../../contexts/ModalReferralContext';
import Date from 'app/components/common/Date';

const MyReferralsList = () => {
  const { handleShow } = useContext(ModalReferralContext);
  const { referrals } = useContext(ReferralRequestContext);

  const renderReferral = member => {
    let memberStatus;

    switch (member.status) {
      case 'active':
        memberStatus = 'activated his/her membership';
        break;
      case 'pending':
        memberStatus = 'membership is under review';
        break;
      case 'inactive':
        memberStatus = 'should submit his/her membership details';
        break;
      case 'not verified':
        memberStatus = 'should verify his/her email address';
        break;
    }

    return (
      <Flex className="mb-2">
        <FontAwesomeIcon
          icon="user"
          className="text-success me-3"
          transform="down-5"
        />
        <div className="flex-1">
          <p className="mb-0">
            <span className="fw-medium">{member.name}</span>
            <br />
            <span>{memberStatus}</span>
          </p>
          <p className="fs--1 mb-0 text-500">
            <Date date={member.updatedAt} format="human" />
          </p>
        </div>
      </Flex>
    );
  };

  return (
    <Card className="h-100 mb-3">
      <Card.Header>
        <Row>
          <Col>
            <h6 className="mt-2">My Referrals</h6>
          </Col>
          <Col xs="auto">
            <IconButton
              iconClassName="fs--2"
              variant="falcon-default"
              size="sm"
              icon="plus"
              onClick={handleShow}
            >
              Add
            </IconButton>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="border-top">
        {referrals.length > 0 &&
          referrals
            .slice(0, 3)
            .map(member => (
              <span key={member.id}>{renderReferral(member)}</span>
            ))}
        {referrals.length > 0 ? (
          <div className="mt-3 ps-4">
            <Link to="/dashboard/referrals">View All</Link>
          </div>
        ) : (
          <span className="text-500">No referrals yet</span>
        )}
      </Card.Body>
    </Card>
  );
};

export default MyReferralsList;
