import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import BasicECharts from 'components/common/BasicEChart';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';

import ReferralChartItem from './ReferralChartItem';
import ReferralRequestContext from '../../contexts/ReferralRequestContext';

const ReferralsChart = () => {
  const { referrals } = useContext(ReferralRequestContext);

  const count = desiredStatus => {
    return referrals.reduce((acc, member) => {
      if (member.status === desiredStatus) {
        acc += 1;
      }

      return acc;
    }, 0);
  };

  const dataset = [
    {
      id: 0,
      name: 'Not Verified',
      value: count('not verified'),
      status: 'notVerified',
      color: '#B6C1D2'
    },
    {
      id: 1,
      name: 'Inactive',
      value: count('inactive'),
      status: 'inactive',
      color: '#748194'
    },
    {
      id: 2,
      name: 'Pending',
      value: count('pending'),
      status: 'pending',
      color: '#F5803E'
    },
    {
      id: 3,
      name: 'Active',
      value: count('active'),
      status: 'active',
      color: '#00D27A'
    }
  ];

  const total = dataset.reduce((acc, val) => val.value + acc, 0);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    PieChart,
    CanvasRenderer
  ]);

  const getOptions = (data, radius) => ({
    color: [data[0].color, data[1].color, data[2].color, data[3].color],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },
    series: [
      {
        // name: ((100 * count('active')) / total).toFixed(1) + '%',
        name: total,
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('card-bg')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 23,
          color: getColor('dark')
        },
        data
      }
    ]
  });

  return (
    <Row className="justify-content-between g-0">
      <Col xs={6} sm={6} xxl className="pt-3">
        <Row>
          {dataset.map((item, index) => (
            <ReferralChartItem
              item={item}
              index={index}
              key={index}
              total={total}
            />
          ))}
        </Row>
      </Col>
      <Col xs="auto">
        <div className="ps-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions(dataset, ['100%', '80%'])}
            style={{ width: '6.625rem', height: '6.625rem' }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ReferralsChart;
