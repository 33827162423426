import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { postWithdraw } from 'app/api/MemberService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import UserContext from 'app/context/UserContext';

import axios from 'axios';
import classNames from 'classnames';

import 'app/helpers/initFA';

const WithdrawModal = () => {
  const { user } = useContext(UserContext);

  // modal handling
  const [show, setShow] = useState(false);

  // form
  const [formData, setFormData] = useState({
    amount: '',
    walletAddress: ''
  });

  // AJAX handling
  const [isSuccess, setIsSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setShow(false);
    setErrors([]);
    setInterval(setIsSuccess(false), 1000);
    setFormData({
      amount: '',
      walletAddress: ''
    });
  };

  const handleShow = () => setShow(true);

  const handleWithdrawal = e => {
    e.preventDefault();
    withdraw();
  };

  function feedback(errors, field) {
    let filtred = errors.reduce((list, error) => {
      if (error.param === field) {
        list.push(error);
      }

      return list;
    }, []);

    const list = filtred.map((error, i) => <li key={i}>{error.msg}</li>);

    return <ul className="list-unstyled">{list}</ul>;
  }

  function isFieldValid(errors, field) {
    let flag = false;
    errors.forEach(error => {
      if (error.param === field) {
        flag = true;
      }
    });

    return flag;
  }

  function withdraw() {
    setIsLoading(true);

    axios
      .request(
        postWithdraw(user.token, formData.amount, formData.walletAddress)
      )
      .then(() => {
        setIsSuccess(true);
      })
      .catch(error => {
        setIsValid(false);
        setErrors(error?.response?.data?.errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Button
        variant="outline-primary"
        className="mt-3"
        style={{ minWidth: '120px' }}
        onClick={handleShow}
      >
        Withdraw
      </Button>

      <Modal show={show} onHide={handleClose} keyboard={true}>
        <Modal.Header>
          <Modal.Title>Withdraw Tokens</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form className={classNames(isSuccess && 'd-none')}>
            <Form.Group className="mb-3" controlId="amount">
              <Form.Label>
                Enter the amount you would like to withdraw:
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="1000"
                name="amount"
                className={classNames(
                  !isValid && isFieldValid(errors, 'amount') && 'is-invalid'
                )}
                disabled={isLoading && true}
                value={formData.amount}
                onChange={handleFieldChange}
              />
              <Form.Control.Feedback type="invalid">
                {feedback(errors, 'amount')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="wallet">
              <Form.Label>Enter your wallet address:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your Wallet"
                className={classNames(
                  !isValid &&
                    isFieldValid(errors, 'walletAddress') &&
                    'is-invalid'
                )}
                disabled={isLoading}
                value={formData.walletAddress}
                onChange={handleFieldChange}
                maxLength="150"
                name="walletAddress"
              />
              <Form.Control.Feedback type="invalid">
                {feedback(errors, 'walletAddress')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="actions" className="mt-2">
              <Col>Using Network: Tron (TRC20)</Col>
              <Flex justifyContent="between">
                <Button
                  onClick={handleWithdrawal}
                  variant="primary"
                  type="submit"
                  disabled={
                    isLoading || !formData.walletAddress || !formData.amount
                  }
                >
                  {isLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="fs--1 mr-2"
                    />
                  )}{' '}
                  Request Withdrawal
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  Close
                </Button>
              </Flex>
            </Form.Group>
          </Form>

          <Alert
            variant="success"
            className={classNames(
              'mb-0 text-center p-5',
              !isSuccess && 'd-none'
            )}
          >
            <FontAwesomeIcon icon="check-circle" className="fs-5" />
            <p className="mt-4">
              Your withdrawal request sent successfully. <br />
              Please, wait for Administrator to handle it.
            </p>
          </Alert>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawModal;
