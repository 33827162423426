import React from 'react';
import PropTypes from 'prop-types';

const Thead = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns?.map(item => (
          <th scope="col" key={item}>
            {item}
          </th>
        ))}
      </tr>
    </thead>
  );
};

Thead.propTypes = {
  columns: PropTypes.any
};

export default Thead;
