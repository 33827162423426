import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Overlay, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { getReferralCode } from 'app/api/MemberService';

const ReferalLinkForm = () => {
  const [showModal] = useState(false);
  const [showToogle, setShowToogle] = useState(false);

  const { response, sendData } = useRequest();
  const { user } = useContext(UserContext);
  const clipboard = {
    value: '',
    copied: false
  };

  const target = useRef(null);
  const copyRef = useRef(null);

  useEffect(() => {
    sendResponse();
  }, []);

  const referralCode = useMemo(() => {
    return response?.data ? response.data.referralCode : '';
  }, [response]);

  const copyLinkText = useMemo(() => {
    return referralCode
      ? `${window.location.origin}/register?referral-id=${referralCode}`
      : '';
  }, [referralCode]);

  function sendResponse() {
    const config = getReferralCode(user.token, user.id);
    sendData(config);
  }

  useEffect(() => {
    if (showModal) {
      copyRef.current.select();
    }
  }, [showModal]);

  return (
    <Form className="row g-3 d-flex justify-content-between">
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label>Share your referral link:</Form.Label>
        <Row>
          <Col>
            <Form.Control
              type="text"
              className="invitation-link"
              defaultValue={copyLinkText}
              ref={copyRef}
              disabled={true}
              readOnly={true}
            />
          </Col>
          <Col xs={1} className="p-0">
            <span
              ref={target}
              onClick={() => setShowToogle(!showToogle)}
              className="d-inline-block"
              style={{ marginTop: '6px' }}
            >
              <CopyToClipboard
                onCopy={() => {
                  clipboard.copied;
                }}
                text={copyLinkText}
              >
                <FontAwesomeIcon icon="copy" className="fs-2"></FontAwesomeIcon>
              </CopyToClipboard>
              <Overlay
                target={target.current}
                show={showToogle}
                placement="top"
              >
                {props => (
                  <Tooltip id="overlay-example" {...props}>
                    Copied!
                  </Tooltip>
                )}
              </Overlay>
            </span>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default ReferalLinkForm;
