import { useContext, useMemo, useEffect, useState } from 'react';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { getReferrals } from 'app/api/MemberService';

const useReferralRequest = () => {
  const { user } = useContext(UserContext);
  const { response, sendData, loading } = useRequest();
  const [referrals, setReferrals] = useState([]);

  useMemo(() => {
    setReferrals(response?.data ? response?.data : []);
  }, [response]);

  useEffect(() => {
    sendResponse();
  }, []);

  function sendResponse() {
    const config = getReferrals(user.token, user.id);
    sendData(config);
  }

  return {
    referrals,
    isLoading: loading
  };
};

export default useReferralRequest;
