import 'app/helpers/initFA';

export const dashboard = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'th',
      to: '/dashboard',
      exact: true
    }
  ]
};

export const settings = {
  label: 'Settings',
  labelDisable: true,
  children: [
    {
      name: 'Settings',
      active: true,
      icon: 'cog',
      to: '/dashboard/settings',
      exact: true
    }
  ]
};

export const referral = {
  label: 'Referrals',
  labelDisable: true,
  children: [
    {
      name: 'Referrals',
      active: true,
      icon: 'users',
      to: '/dashboard/referrals',
      exact: true
    }
  ]
};

export const commitments = {
  label: 'Commitments',
  labelDisable: true,
  children: [
    {
      name: 'Commitments',
      active: true,
      icon: 'play',
      to: '/dashboard/commitments',
      exact: true
    }
  ]
};

export const chat = {
  label: 'Chat',
  labelDisable: true,
  children: [
    {
      name: 'Chat',
      active: true,
      icon: 'comments',
      to: '/dashboard/Chat',
      exact: true
    }
  ]
};

export const seminars = {
  label: 'Seminars',
  labelDisable: true,
  children: [
    {
      name: 'Seminars',
      active: true,
      icon: 'video',
      to: '/dashboard/seminars',
      exact: true
    }
  ]
};

export default [dashboard, commitments, seminars, referral, settings, chat];
