import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import WithdrawModal from '../withdraw/WithdrawModal';
import 'app/helpers/initFA';

const Withdraw = () => {
  return (
    <Row>
      <Col xs={12}>
        <FontAwesomeIcon
          className="text-primary"
          icon="arrow-circle-up"
          size="3x"
        ></FontAwesomeIcon>
      </Col>
      <Col xs={12}>
        <WithdrawModal />
      </Col>
    </Row>
  );
};

export default Withdraw;
