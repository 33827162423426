import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { getContents } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import { ContentsContext } from './CommitmentsContext';

const ContentsProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [contents, setContents] = useState([]);
  const [loaded, setLoaded] = useState(false);

  ////////////////////////////////////////////////
  const [isPlay, setIsPlay] = useState(true);
  const [isPlayMemory, setIsPlayMemory] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  const keepIsPlay = () => {
    setIsPlayMemory(isPlay);
  };

  const rememberIsPlay = () => {
    return setIsPlay(isPlayMemory);
  };

  ////////////////////////////////////////////////

  const load = () => {
    setLoaded(false);

    axios.request(getContents(user.token, user.id)).then(response => {
      setContents(response.data);
      setLoaded(true);
    });
  };

  useEffect(() => {
    load();
  }, []);

  const value = {
    contents,
    loaded,
    load,
    ///////////////
    isPlay,
    setIsPlay,
    keepIsPlay,
    rememberIsPlay,
    isMuted,
    setIsMuted
    ///////////////
  };

  return (
    <ContentsContext.Provider value={value}>
      {children}
    </ContentsContext.Provider>
  );
};

ContentsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ContentsProvider;
