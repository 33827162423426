import React from 'react';
import { Helmet } from 'react-helmet';
import InvitePeople from './components/InvitePeople';
import RefarralsTable from './components/RefarralsTable';

export default function ReferralMember() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Referral System | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <InvitePeople />
      <RefarralsTable />
    </>
  );
}
