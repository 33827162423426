import React from 'react';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'app/assets/css/custom.css';
import { Card } from 'react-bootstrap';

const AudioPlayer = ({ media }) => {
  return (
    <Card className="overflow-hidden">
      <Card.Header>{media.title}</Card.Header>
      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <Flex
          id="seminar-player"
          direction="column"
          alignContent="stretch"
          className="h-100"
        >
          <div className="w-100 flex-grow-1">
            <Flex justifyContent="center" alignItems="center" className="h-100">
              <FontAwesomeIcon
                icon="headphones"
                className="text-primary"
                style={{ fontSize: '60px' }}
              />
            </Flex>
          </div>

          <div className="w-100" style={{ height: '40px' }}>
            <audio controls src={media.path} className="w-100" />
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default AudioPlayer;
