import React, { useContext } from 'react';

import { Card, Row } from 'react-bootstrap';

import { SlotsContext } from '../context/CommitmentsContext';
import Slot from './Slot';

const Grid = () => {
  const { slots } = useContext(SlotsContext);

  return (
    <>
      <Card className="mb-4 p-0">
        <Card.Body style={{ padding: '16px 26px 0px 26px' }}>
          <Row>
            {slots.map((slot, idx) => {
              return <Slot key={idx} slot={slot} />;
            })}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

Grid.propTypes = {};

export default Grid;
