import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const ViewerVideo = ({ content, controls, handleEnd }) => {
  return (
    <>
      <ReactPlayer
        url={content.filename}
        controls={false}
        playing={controls.isPlay}
        muted={controls.isMuted}
        playsinline={true}
        onEnded={handleEnd}
        width={'100%'}
        height={'100%'}
        className="v-player"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
        onContextMenu={e => e.preventDefault()}
      />
    </>
  );
};

ViewerVideo.propTypes = {
  content: PropTypes.object,
  controls: PropTypes.object,
  handleEnd: PropTypes.func
};

export default ViewerVideo;
