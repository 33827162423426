import React, { useContext, useEffect, useState } from 'react';

import Grid from './slots/Grid';
import Viewer from './viewer/Viewer';
import SlotsProvider from './context/SlotsProvider';
import Controls from './Controls';
import { ContentsContext } from './context/CommitmentsContext';
import { Col, Row } from 'react-bootstrap';
import DailyViews from './daily-views/DailyViews';
import DailyRewards from './daily-rewards/DailyRewards';

const Content = () => {
  const { isPlay, setIsPlay } = useContext(ContentsContext);

  const [isPlayBuf, setIsPlayBuf] = useState(isPlay);
  const [isFocus, setIsFocus] = useState(true);

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  useEffect(() => {
    if (isFocus) {
      setIsPlay(isPlayBuf);
    } else {
      setIsPlayBuf(isPlay);
      setIsPlay(false);
    }
  }, [isFocus]);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <>
      <SlotsProvider>
        <Controls />
        <Grid />
        <Viewer />
        <Row className="g-3 mb-3">
          <Col lg={3}>
            <DailyViews />
          </Col>
          <Col lg={9}>
            <DailyRewards />
          </Col>
        </Row>
      </SlotsProvider>
    </>
  );
};

export default Content;
