import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';

const BlankPlayer = () => {
  return (
    <Card className="overflow-hidden">
      <Card.Header>&rsaquo;</Card.Header>
      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <Flex className="h-100 justify-content-center align-items-center">
          <span>Choose media</span>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default BlankPlayer;
