import React, { useContext, useEffect, useState } from 'react';

import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SeminarContext } from '../../context/SeminarContext';
import { getMediaType } from 'app/helpers/media';
import classNames from 'classnames';
import 'app/assets/css/custom.css';

const Lock = ({ isLocked }) => {
  return (
    isLocked && (
      <FontAwesomeIcon icon="lock" className="ml-left"></FontAwesomeIcon>
    )
  );
};

const PlaylistItem = ({ media }) => {
  const { setMedia: play } = useContext(SeminarContext);

  const [icon, setIcon] = useState('file');

  useEffect(() => {
    setIcon(pickIcon(media));
  }, [media]);

  function pickIcon(media) {
    let iconMap = {
      video: 'film',
      audio: 'headphones',
      image: 'image',
      file: 'file-pdf',
      pdf: 'file-pdf',
      link: 'external-link-alt'
    };

    let contentType = getMediaType(media.mime);

    return iconMap[contentType] || 'file';
  }

  function playMedia() {
    media.hasAccess && play(media);
  }

  return (
    <Flex
      alignItems="center"
      className={classNames(
        'hover-actions-trigger hover-bg-100 border-bottom cursor-pointer p-3 playlist-item text-700',
        { locked: !media.hasAccess }
      )}
      onClick={playMedia}
    >
      <div className="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
        <FontAwesomeIcon icon={icon} className="text-primary" />
      </div>
      <div className="ms-3 flex-shrink-1 flex-grow-1">
        <h6 className="p-0 m-0">{media.title}</h6>
      </div>
      <Lock isLocked={!media.hasAccess} />
    </Flex>
  );
};

export default PlaylistItem;
