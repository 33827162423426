import React from 'react';
import PropTypes from 'prop-types';
import 'app/helpers/initFA';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import gaussRound from 'app/helpers/gaussRound';

const MembershipCash = ({ membership }) => {
  return (
    <>
      <h6 className="text-primary">Referrals</h6>
      <p className="fs-0 mb-0 fw-semi-bold">
        Invite your friends to{' '}
        <Link to="/dashboard/referrals">join the platform</Link>. Once they
        activate their account, you will get:
      </p>

      <ul className="fa-ul ms-2 ps-card mb-2 mt-2">
        {Object.keys(membership?.referrals || {}).map((item, idx) => (
          <li key={idx} className="py-1">
            <h6 className="text-700">
              <FontAwesomeIcon icon="plus" className="fa-li text-success" />
              {gaussRound(
                Math.ceil(membership.referrals[item] * membership.price)
              )}{' '}
              tokens for{' '}
              {item !== 'more' ? `${item}st referral` : 'all further referrals'}
            </h6>
          </li>
        ))}
      </ul>

      <hr />
      <h6 className="text-primary">Daily Commitments</h6>

      <p className="fs-0 mb-0 fw-semi-bold">
        View the <Link to="/dashboard/commitments">content</Link> suggested by
        the platform in order to get:
      </p>

      <ul className="fa-ul ms-2 ps-card mb-2 mt-2">
        <li className="py-1">
          <h6 className="text-700">
            <FontAwesomeIcon icon="plus" className="fa-li text-success" />1
            token for {membership?.contentView} views (weekly limit is{' '}
            {membership?.weeklyTokenLimit} tokens)
          </h6>
        </li>
      </ul>

      <hr />
      <h6 className="text-primary">Upgrading your membership</h6>

      <p className="fs-0 mb-0 fw-semi-bold">
        By upgrading your account you then gain access to earning 10x more
        tokens per content views. Along with 10x the reward for all referrals.
      </p>
    </>
  );
};

MembershipCash.propTypes = {
  membership: PropTypes.object
};

export default MembershipCash;
