import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FalconCardHeader from 'components/common/FalconCardHeader';

const Membership = ({ subsc }) => {
  const Field = ({ fname = '', fvalue = '' }) => {
    return (
      <Row className="align-items-center py-2 border-bottom border-100">
        <Col xs={4} className="py-1">
          <span className="fw-medium">{fname}</span>
        </Col>
        <Col xs={8}>{fvalue}</Col>
      </Row>
    );
  };

  Field.propTypes = {
    fname: PropTypes.string.isRequired,
    fvalue: PropTypes.string.isRequired
  };

  return (
    <>
      <Card className="h-100">
        <FalconCardHeader title="Membership" className="bg-light" />
        <Card.Body className="py-0">
          <Field
            fname={'Level'}
            fvalue={subsc?.membershipLevel ? subsc?.membershipLevel : ''}
          />
          <Field
            fname={'Start Date'}
            fvalue={
              subsc?.startedAt
                ? moment(subsc.startedAt).format('DD-MM-YYYY')
                : ''
            }
          />
          <Field
            fname={'Amount Paid'}
            fvalue={subsc?.amount ? `${subsc.amount}` : ''}
          />
          <Field
            fname={'Receipt No'}
            fvalue={subsc?.receiptNumber ? `${subsc.receiptNumber}` : ''}
          />
        </Card.Body>
      </Card>
    </>
  );
};

Membership.propTypes = {
  subsc: PropTypes.object
};

export default Membership;
