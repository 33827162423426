import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { isMobile } from 'react-device-detect';

const MembershipLevelCard = ({ level, isSroll, handlerShowModal }) => {
  const cardGold = useRef(null);

  useEffect(() => {
    scrollToAvailableCard();
  }, [cardGold]);

  const scrollToAvailableCard = () => {
    if (isSroll) {
      cardGold?.current?.offsetTop &&
        isMobile &&
        window.scrollTo({
          top: cardGold.current.offsetTop + 110,
          behavior: 'smooth'
        });
    }
  };

  return (
    <>
      <Col
        ref={level.available ? cardGold : null}
        className={classNames('mb-4 mb-lg-1', {
          'dark__bg-1000 px-4 px-lg-0': level.isFeatured
        })}
        lg={4}
        style={{
          backgroundColor: level.isFeatured && 'rgba(115, 255, 236, 0.18)'
        }}
      >
        <div className={'h100' + (level.available ? '' : ' text-300')}>
          <div className="text-center p-2 p-lg-3 mb-2">
            {level.isFeatured ? (
              <Badge bg="warning" text="dark" className="mb-2">
                POPULAR CHOICE
              </Badge>
            ) : (
              <Badge
                bg="warning"
                text="dark"
                className="mb-2"
                style={{ visibility: 'hidden' }}
              >
                |
              </Badge>
            )}
            <h3
              className={
                'fw-normal my-0' + (level.available ? '' : ' text-300')
              }
            >
              {level.title}
            </h3>
            <p className="mt-3" style={{ minHeight: '48px' }}>
              {parse(level.subTitle)}
            </p>
            <Button
              className={level.available ? '' : 'opacity-25'}
              disabled={level.available ? false : true}
              variant={level.isFeatured ? 'primary' : 'outline-primary'}
              onClick={() => handlerShowModal(level)}
            >
              {level.buttonText}
            </Button>
          </div>
          <hr className="border-bottom-0 m-0" />
          <div className="px-sm-4 py-0 py-lg-2 d-flex flex-column align-items-center">
            <h5
              className={
                'fw-medium fs-0 mt-3' + (level.available ? '' : ' text-300')
              }
            >
              {level.featuresTitle}
            </h5>
            <ul className="list-unstyled mt-3">
              {level.featuresList.map(feature => (
                <li
                  className="py-1"
                  key={feature.id}
                  style={{
                    listStyle: 'none',
                    textIndent: '-27px',
                    marginLeft: '28px'
                  }}
                >
                  <FontAwesomeIcon
                    icon="check"
                    className={
                      'me-2 text-success' + (level.available ? '' : 'text-300')
                    }
                  />{' '}
                  {feature.title}{' '}
                  {feature.tag && (
                    <SoftBadge pill bg={feature.tag.type}>
                      {feature.tag.label}
                    </SoftBadge>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Col>
    </>
  );
};

MembershipLevelCard.propTypes = {
  level: PropTypes.object,
  isSroll: PropTypes.bool,
  handlerShowModal: PropTypes.func
};

export default MembershipLevelCard;
