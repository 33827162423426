import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';

import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getToday
} from 'app/helpers/date';

const today = getToday();
const currFirstDay = getFirstDayOfWeek(today);
const currLastDay = getLastDayOfWeek(today);

const bufDate = new Date(today);
const prevday = new Date(bufDate.setDate(bufDate.getDate() - 7));
const prevFirstDay = getFirstDayOfWeek(prevday);
const prevLastDay = getLastDayOfWeek(prevday);

const calcWeekSum = (transations, firstDay, lastDay) => {
  return transations
    .filter(transaction => {
      let currentDay = new Date(transaction.createdAt);
      return currentDay >= firstDay && currentDay <= lastDay;
    })
    .reduce((accumulator, transaction) => {
      return accumulator + transaction.amount;
    }, 0);
};

const calcPercent = (currentWeekSum, previousWeekSum) => {
  return ((currentWeekSum * 100) / previousWeekSum - 100).toFixed(1);
};

const WeeklyRewardsSum = ({ transations }) => {
  const currWeekSum = calcWeekSum(transations, currFirstDay, currLastDay);
  const prevWeekSum = calcWeekSum(transations, prevFirstDay, prevLastDay);
  const percent =
    prevWeekSum !== 0 ? calcPercent(currWeekSum, prevWeekSum) : null;

  const renderBadge = percent => {
    return percent === null ? (
      <SoftBadge pill bg="info" className="me-2">
        &mdash;
      </SoftBadge>
    ) : (
      <SoftBadge
        pill
        bg={classNames(percent > 0 ? 'success' : 'info')}
        className="me-2"
      >
        <FontAwesomeIcon
          icon={classNames(percent > 0 ? 'caret-up' : 'caret-down')}
          className="me-1"
        />
        {Math.abs(percent)}%
      </SoftBadge>
    );
  };

  return (
    <div>
      <h2 className="fw-normal text-700 mb-1 lh-1">
        <CountUp end={currWeekSum} duration={2} separator="," />
        <span style={{ color: '#50AF95' }}> USDT</span>
      </h2>
      {renderBadge(percent)}
    </div>
  );
};

WeeklyRewardsSum.propTypes = {
  transations: PropTypes.array
};

export default WeeklyRewardsSum;
