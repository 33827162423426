import React from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumb, Col, Row } from 'react-bootstrap';

import PageHeader from 'components/common/PageHeader';
import SeminarPlaylist from './components/playlist/SeminarPlaylist';
import SeminarPlayer from './components/player/SeminarPlayer';
import SeminarProvider from './context/SeminarProvider';
import useApi from 'app/hooks/useApi';
import { Link, useParams } from 'react-router-dom';
import { viewSeminar, viewSeminarSection } from 'app/api/SeminarConfig';
import SectionComments from './components/comments/SectionComments';

export default function Seminar() {
  const { seminarId } = useParams();
  const { sectionId } = useParams();
  const { data: seminar } = useApi(viewSeminar(seminarId));
  const { data: section } = useApi(viewSeminarSection(sectionId));

  if (!section || !seminar) {
    return null;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{section.title}</title>
      </Helmet>

      <PageHeader title={section.title} className="mb-3">
        <Breadcrumb className="mt-1">
          <Breadcrumb.Item href="">
            <Link to="/dashboard/seminars">Seminars</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="">
            <Link to={`/dashboard/seminars/${seminar.id}`}>
              {seminar.title}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{section.title}</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>

      <Row className="g-3 mb-3">
        <SeminarProvider>
          <Col lg={7}>
            <SeminarPlayer />
          </Col>
          <Col lg={5}>
            <SeminarPlaylist />
          </Col>
        </SeminarProvider>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7}>
          <SectionComments section={section} />
        </Col>
        <Col lg={5}>{/* tagline block  */}</Col>
      </Row>
    </>
  );
}
