import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';

const ViewerControls = ({ controls, setControls }) => {
  const handlePlay = () => {
    setControls({ ...controls, isPlay: !controls.isPlay });
  };

  const handleMuted = () => {
    setControls({ ...controls, isMuted: !controls.isMuted });
  };

  return (
    <>
      <div className="d-flex justify-content-center mb-3">
        <Button onClick={handlePlay} variant="link" size="lg">
          <FontAwesomeIcon
            icon={controls.isPlay ? 'pause' : 'play'}
          ></FontAwesomeIcon>
        </Button>
        &nbsp;
        <Button onClick={handleMuted} variant="link" size="lg">
          <FontAwesomeIcon
            icon={controls.isMuted ? 'volume-mute' : 'volume-up'}
          ></FontAwesomeIcon>
        </Button>
      </div>
    </>
  );
};

ViewerControls.propTypes = {
  controls: PropTypes.object,
  setControls: PropTypes.func
};

export default ViewerControls;
