import React, { useContext } from 'react';
import { ProgressBar } from 'react-bootstrap';
import ReferralRequestContext from '../../contexts/ReferralRequestContext';

const ReferralProgress = () => {
  const { referrals } = useContext(ReferralRequestContext);

  const totalActive = referrals.reduce((acc, member) => {
    if (member.status === 'active' && acc < 3) {
      acc += 1;
    }
    return acc;
  }, 0);

  const percent = ((totalActive * 100) / 3).toFixed(0);

  return (
    <>
      <p className="mb-2 text-primary fs-1">{percent}% completed</p>
      <ProgressBar
        now={percent}
        key={1}
        style={{ height: '6px' }}
        className="rounded-pill mb-1"
      />
      <p className="fs--1 text-600">The target is to get 3 active referrals</p>
    </>
  );
};

export default ReferralProgress;
