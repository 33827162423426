import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DashboardContext from './DashboardContext';

const DashboardProvider = ({ children }) => {
  const [referrals, setReferrals] = useState([]);
  const [commitments, setCommitments] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const value = {
    referrals,
    setReferrals,
    commitments,
    setCommitments,
    transactions,
    setTransactions
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DashboardProvider;
