import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import UserContext from 'app/context/UserContext';
import { useNavigate } from 'react-router-dom';

function useApi(config, autoFetch = true) {
  const { user, removeUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);

  const attachToken = config => {
    return (config = {
      ...config,
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsLoaded(false);

    try {
      const result = await axios.request(attachToken(config));
      setData(result.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        removeUser();
        navigate('/login');
      }

      setError(error);
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (autoFetch) {
      fetchData();
    }
  }, [config.url, autoFetch]);

  return {
    data,
    isLoading,
    isLoaded,
    error,
    ...(autoFetch ? {} : { makeRequest: fetchData })
  };
}

export default useApi;
