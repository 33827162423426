import React, { useContext, useEffect, useState } from 'react';

import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { getCommitments } from 'app/api/MemberService';
import WeeklyCommitmentsChart from './WeeklyCommitmentsChart';
import WeeklyCommitmentsSum from './WeeklyCommitmentsSum';
import DashboardContext from '../../contexts/DashboardContext';

const WeeklyCommitments = () => {
  const { setCommitments: setCommitmentsToContext } =
    useContext(DashboardContext);

  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const [commitments, setCommitments] = useState([]);

  useEffect(() => {
    if (!response) {
      sendData(getCommitments(user.token, user.id));
    }
  }, []);

  useEffect(() => {
    if (response) {
      setCommitments(response.data);
      setCommitmentsToContext(response.data);
    }
  }, [response]);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">
          Weekly Commitments
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Your progress this week compared to the previous week.
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'question-circle']}
                transform="shrink-1"
                className="ms-1 text-400"
                id="weeklySalesTooltip"
              />
            </span>
          </OverlayTrigger>
        </h6>
      </Card.Header>

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <WeeklyCommitmentsSum commitments={commitments} />
        <WeeklyCommitmentsChart commitments={commitments} />
      </Card.Body>
    </Card>
  );
};

export default WeeklyCommitments;
