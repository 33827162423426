import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';

const StillViewingModal = ({ modalShow, setModalShow, setIsPlay }) => {
  const handleModalHide = () => {
    setModalShow(false);
    setIsPlay(true);
  };

  return (
    <Modal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center my-3">
        <p className="fw-semi-bold fs-1">
          Are you still viewing
          <br /> this page?
        </p>
        <Button
          variant="outline-primary"
          onClick={handleModalHide}
          className="px-4 mt-2"
        >
          Yes
        </Button>
      </Modal.Body>
    </Modal>
  );
};

StillViewingModal.propTypes = {
  modalShow: PropTypes.bool,
  setModalShow: PropTypes.func,
  setIsPlay: PropTypes.func
};

export default StillViewingModal;
