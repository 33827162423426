import React from 'react';
import Helmet from 'react-helmet';

import PageHeader from 'components/common/PageHeader';

import ContentsProvider from './components/context/ContentsProvider';
import Content from './components/Content';
import 'app/assets/css/custom.css';
import 'app/helpers/initFA';

const Commitments = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Commitments | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <PageHeader
        title="Commitments"
        description=""
        className="mb-3"
      ></PageHeader>

      <ContentsProvider>
        <Content />
      </ContentsProvider>
    </>
  );
};

export default Commitments;
