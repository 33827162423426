import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SeminarContext } from './SeminarContext';

const SeminarProvider = ({ children }) => {
  const [media, setMedia] = useState();

  useEffect(() => {
    if (!media) {
      return;
    }
  }, [media]);

  const value = {
    media: media,
    setMedia: setMedia
  };

  return (
    <SeminarContext.Provider value={value}>{children}</SeminarContext.Provider>
  );
};

SeminarProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SeminarProvider;
