import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl;

export const seminarList = () => {
  return {
    url: `${endpointUrl}/seminars`,
    method: 'GET'
  };
};

export const viewSeminar = id => {
  return {
    url: `${endpointUrl}/seminars/${id}`,
    method: 'GET'
  };
};

export const viewSeminarSection = id => {
  return {
    url: `${endpointUrl}/seminar-sections/${id}`,
    method: 'GET'
  };
};

export const viewSeminarSections = id => {
  return {
    url: `${endpointUrl}/seminars/${id}/sections`,
    method: 'GET'
  };
};

export const viewSeminarSectionMedia = sectionId => {
  return {
    url: `${endpointUrl}/seminar-sections/${sectionId}/media`,
    method: 'GET'
  };
};
