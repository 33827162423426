import React from 'react';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';

import PageHeader from 'components/common/PageHeader';

export default function Chat() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Chat | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <PageHeader title="Chat" description="" className="mb-3"></PageHeader>

      <Card>
        <Card.Body>
          <sub className="fs-1">Coming soon...</sub>
        </Card.Body>
      </Card>
    </>
  );
}
