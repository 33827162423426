import React from 'react';
import NavbarStandard from '../../common/NavbarStandard';
import Footer from '../../common/Footer';
import Hero from '../../common/Hero';
import '../../assets/css/custom.css';
import Content from './Content';
import Section from 'app/public/common/Section';
import { Card } from 'react-bootstrap';
import Helmet from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | MineChain</title>
      </Helmet>
      <NavbarStandard />
      <Hero />
      <Section>
        <Card>
          <Card.Body>
            <Content />
          </Card.Body>
        </Card>
      </Section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
