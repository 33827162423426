import React, { useContext, useEffect, useState } from 'react';

import Flex from 'components/common/Flex';
import { Modal, CloseButton } from 'react-bootstrap';

import { ContentsContext, SlotsContext } from '../context/CommitmentsContext';
import ViewerStillViewing from './ViewerStillViewing';
import ViewerControls from './ViewerControls';
import ViewerVideo from './ViewerVideo';
import ViewerImage from './ViewerImage';

const Viewer = () => {
  const { current, setCurrent, next, watched } = useContext(SlotsContext);
  const { rememberIsPlay } = useContext(ContentsContext);

  const [modalShow, setModalShow] = useState(false);
  const [isViewing, setIsViewing] = useState(true);
  const [askIsHere, setAskIsHere] = useState(false);

  const [controls, setControls] = useState({
    isPlay: false,
    isMuted: false
  });

  const handleCloseViewer = () => {
    setCurrent(null);
    setModalShow(false);
    setAskIsHere(false);
    setIsViewing(false);
    rememberIsPlay();
  };

  const handleEnd = () => {
    if (!isViewing) {
      setAskIsHere(true);
    } else {
      watched(current);
      next(current);
    }
  };

  const handleIsHere = () => {
    setIsViewing(true);
    setAskIsHere(false);

    watched(current);
    next(current);
  };

  const play = content => {
    switch (content.type) {
      case 'video':
        return (
          <ViewerVideo
            content={content}
            controls={controls}
            handleEnd={handleEnd}
          />
        );
      case 'image':
        return (
          <ViewerImage
            content={content}
            handleEnd={handleEnd}
            isPlay={controls.isPlay}
          />
        );
    }
  };

  const check = () => {
    return <ViewerStillViewing confirm={handleIsHere} />;
  };

  useEffect(() => {
    if (!isViewing) {
      return null;
    }

    let leftTime = 300;
    let interval = setInterval(() => {
      if (leftTime > 1 && current) {
        leftTime -= 1;
      } else {
        setIsViewing(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isViewing]);

  useEffect(() => {
    controls.isPlay ? setIsViewing(true) : setIsViewing(false);
  }, [controls]);

  useEffect(() => {
    if (current) {
      setModalShow(true);
      setControls({ ...controls, isPlay: true });
    }
  }, [current]);

  return (
    <>
      <Modal
        show={modalShow}
        onHide={handleCloseViewer}
        keyboard={true}
        fullscreen={true}
      >
        <Modal.Header className="border-bottom-0">
          <Modal.Title></Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={handleCloseViewer}
          />
        </Modal.Header>
        <Modal.Body>
          {current && (
            <Flex
              justifyContent="center"
              alignItems="center"
              className="h-100 w-100"
            >
              {askIsHere ? (
                check()
              ) : (
                <div className="viewer-content">{play(current.data)}</div>
              )}
            </Flex>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center border-top-0">
          {!askIsHere && (
            <ViewerControls controls={controls} setControls={setControls} />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

Viewer.propTypes = {};

export default Viewer;
