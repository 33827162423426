export const format = date => {
  return date;
};

export const getFirstDayOfWeek = d => {
  // clone` date object, so we don't mutate it
  const date = new Date(d);
  const day = date.getDay();
  // day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);

  let firstDay = new Date(date.setDate(diff));
  firstDay.setHours(0, 0, 0, 0);

  return firstDay;
};

export const getLastDayOfWeek = d => {
  const bufDay = new Date(getFirstDayOfWeek(d));
  const lastDay = new Date(bufDay.setDate(bufDay.getDate() + 6));
  lastDay.setHours(0, 0, 0, 0);

  return lastDay;
};

export const getToday = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  return today;
};
