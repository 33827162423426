import React, { useMemo, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ChangePassword from './components/ChangePassword';
import Membership from './components/Membership';
import { Col, Row } from 'react-bootstrap';
import { one } from 'app/api/MemberService';
import ProfileBanner from './components/ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/avatar.png';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import DangerZone from './components/DangerZone';

export default function SettingsMember() {
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  useEffect(() => {
    sendResponse();
  }, []);

  const member = useMemo(() => {
    return response?.data ? response?.data : {};
  }, [response]);

  function sendResponse() {
    const config = one(user.token, user.id);
    sendData(config);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Profile Settings | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={avatar}
          className="mb-3"
        />
        <ProfileBanner.Body>
          <Row>
            <Col lg={8}>
              <h4 className="mb-1">{member?.name}</h4>
              <h5 className="fs-0 fw-normal">{member?.email}</h5>
              <div className="border-dashed-bottom my-4 d-lg-none" />
            </Col>

            <Col className="ps-2 ps-lg-3"></Col>
          </Row>
        </ProfileBanner.Body>
      </ProfileBanner>

      <Row className="g-3">
        <Col lg={4}>
          <Membership subsc={member?.MemberSubscription} />
        </Col>
        <Col lg={4}>
          <ChangePassword />
        </Col>
        <Col lg={4}>
          <DangerZone />
        </Col>
      </Row>
    </>
  );
}
