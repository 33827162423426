import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { forgotPassword } from 'app/api/MemberService';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const ForgotPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: [],
    general: []
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setErrors({
      email: [],
      general: []
    });
    setLoading(true);

    axios
      .request(forgotPassword(email))
      .then(() => {
        toast.success(`Email send to ${email}`, {
          theme: 'colored'
        });
      })
      .catch(error => {
        const errors = {
          email: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
          isInvalid={errors.email.length}
        />
        <FeedbackError className="small" errors={errors} type="email" />
      </Form.Group>

      <Form.Group className="mb-3">
        {!loading ? (
          <Button className="w-100" type="submit" disabled={!email}>
            Send reset link
          </Button>
        ) : (
          <Button variant="primary" className="w-100" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}
      </Form.Group>

      <Form.Group className="mb-4 small mt-2">
        <AlertError errors={errors} type="general" />
      </Form.Group>

      <Link className="fs--1 text-600" to="/login">
        Go to login
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgotPasswordForm.defaultProps = { layout: 'simple' };

export default ForgotPasswordForm;
