import React, { useContext } from 'react';
import { Navbar, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from 'context/Context';
import Logo from 'app/components/common/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import UserContext from 'app/context/UserContext';
import 'app/helpers/initFA';

const NavbarTop = () => {
  const navigate = useNavigate();
  const { removeUser } = useContext(UserContext);

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  function handlerLogout() {
    removeUser();
    navigate('/login');
  }

  return (
    <Navbar className="navbar-glass  fs--1 navbar-top sticky-kit">
      <Logo at="navbar-top" width={180} id="topLogo" />
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isDark', !isDark)}
          >
            <OverlayTrigger
              key="left"
              placement={isRTL ? 'bottom' : 'left'}
              overlay={
                <Tooltip id="ThemeColor">
                  {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => handlerLogout()}
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon icon="door-open" className="fs-0" />
            </div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

export default NavbarTop;
