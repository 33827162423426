import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/members';

export const register = ({
  name,
  email,
  password,
  confirmPassword,
  isAccepted,
  referralCode
}) => {
  return {
    url: `${urlBase}/registration`,
    method: 'POST',
    data: { name, email, password, confirmPassword, isAccepted, referralCode }
  };
};

export const login = (email, password) => {
  return {
    url: `${urlBase}/login`,
    method: 'POST',
    data: { email: email?.trim(), password: password?.trim() }
  };
};

export const check = (token, id) => {
  return {
    url: `${urlBase}/${id}/check`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const verification = hash => {
  return {
    url: `${urlBase}/verification`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + hash
    }
  };
};

export const subscription = (token, { level, receipt, amount, startedAt }) => {
  return {
    url: `${urlBase}/subscription`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { level, receipt, amount, startedAt }
  };
};

export const forgotPassword = email => {
  return {
    url: `${urlBase}/forgot-password`,
    method: 'POST',
    data: { email }
  };
};

export const resetPassword = (token, password, confirmPassword) => {
  return {
    url: `${urlBase}/reset-password`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { password, confirmPassword }
  };
};

export const one = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const changePassword = (
  token,
  id,
  { confirmPassword, newPassword, oldPassword }
) => {
  return {
    url: `${urlBase}/${id}/password`,
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { newPassword, confirmPassword, oldPassword }
  };
};

export const sendEmailReferral = (token, id, email) => {
  return {
    url: `${urlBase}/${id}/referral/send`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { email }
  };
};

export const getReferralCode = (token, id) => {
  return {
    url: `${urlBase}/${id}/referral-code`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getMembershipLevels = (token, id) => {
  return {
    url: `${urlBase}/${id}/membership-levels`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getMembershipLevel = (token, id) => {
  return {
    url: `${urlBase}/${id}/membership-level`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getMember = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getReferrals = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/referrals`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const postWithdraw = (token, amount, walletAddress) => {
  return {
    url: `${urlBaseApi}/withdrawal-requests`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { amount, walletAddress }
  };
};

export const getBalance = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/balance`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getTransactions = (token, id, days = 14) => {
  return {
    url: `${urlBaseApi}/members/${id}/transactions?days=${days}`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getCommitments = (token, id, days = 14) => {
  return {
    url: `${urlBaseApi}/members/${id}/commitments?days=${days}`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getTotalViews = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/total-views`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getContents = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/contents?limit=50`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const postContent = (token, userId, contentId) => {
  return {
    url: `${urlBaseApi}/members/${userId}/content-views`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { contentId }
  };
};

export const remove = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
