import React, { useState, useContext } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { changePassword } from 'app/api/MemberService';
import { toast } from 'react-toastify';
import FalconCardHeader from 'components/common/FalconCardHeader';
import UserContext from 'app/context/UserContext';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const ChangePassword = () => {
  const { user } = useContext(UserContext);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    oldPassword: [],
    newPassword: [],
    confirmPassword: [],
    general: []
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setErrors({
      oldPassword: [],
      newPassword: [],
      confirmPassword: [],
      general: []
    });
    setLoading(true);

    axios
      .request(changePassword(user.token, user.id, formData))
      .then(() => {
        toast.success(`Password changed successfully`, {
          theme: 'colored'
        });
        setFormData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
      })
      .catch(error => {
        const errors = {
          oldPassword: [],
          newPassword: [],
          confirmPassword: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card className="h-100">
        <FalconCardHeader title="Change Password" className="bg-light" />
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.oldPassword}
                name="oldPassword"
                onChange={handleChange}
                isInvalid={errors.oldPassword.length}
              />
              <FeedbackError errors={errors} type="oldPassword" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.newPassword}
                name="newPassword"
                onChange={handleChange}
                isInvalid={errors.newPassword.length}
              />
              <FeedbackError errors={errors} type="newPassword" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                isInvalid={errors.confirmPassword.length}
              />
              <FeedbackError errors={errors} type="confirmPassword" />
            </Form.Group>

            {!loading ? (
              <Button
                type="submit"
                className="w-100"
                disabled={
                  !formData.oldPassword ||
                  !formData.newPassword ||
                  !formData.confirmPassword
                }
              >
                Update
              </Button>
            ) : (
              <Button className="w-100" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Loading...</span>
              </Button>
            )}

            <Form.Group className="small mt-2">
              <AlertError errors={errors} type="general" />
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChangePassword;
