import React, { useContext } from 'react';

import { SeminarContext } from '../../context/SeminarContext';
import BlankPlayer from './types/DefaultPlayer';
import VideoPlayer from './types/VideoPlayer';
import AudioPlayer from './types/AudioPlayer';
import ImagePlayer from './types/ImagePlayer';
import PdfPlayer from './types/PdfPlayer';
import LinkPlayer from './types/LinkPlayer';
import { getMediaType } from 'app/helpers/media';

const ContentPlayer = ({ media }) => {
  if (!media) {
    return <BlankPlayer />;
  }

  switch (getMediaType(media.mime)) {
    case 'image':
      return <ImagePlayer media={media} />;
    case 'audio':
      return <AudioPlayer media={media} />;
    case 'video':
      return <VideoPlayer media={media} />;
    case 'file':
    case 'pdf':
      return <PdfPlayer media={media} />;
    case 'link':
      return <LinkPlayer media={media} />;
    default:
      return null; // or any other fallback component if needed
  }
};

const SeminarPlayer = () => {
  const { media } = useContext(SeminarContext);

  if (!media) {
    return <BlankPlayer />;
  }

  return <ContentPlayer media={media} />;
};

export default SeminarPlayer;
