import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { ContentsContext } from '../context/CommitmentsContext';

const Video = ({ content, handleWatched }) => {
  const { isPlay, isMuted } = useContext(ContentsContext);

  return (
    <>
      <ReactPlayer
        url={content.filename}
        controls={false}
        playing={isPlay}
        muted={isMuted}
        onEnded={handleWatched}
        width={'100%'}
        height={'100%'}
        playsinline={true}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
        onContextMenu={e => e.preventDefault()}
      />
    </>
  );
};

Video.propTypes = {
  content: PropTypes.object,
  handleWatched: PropTypes.func
};

export default Video;
