import React, { useContext, useEffect, useState } from 'react';
import { getOption } from 'app/api/OptionsService';
import { Card } from 'react-bootstrap';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import createMarkup from 'helpers/createMarkup';

const FAQ = () => {
  const { user } = useContext(UserContext);
  const [desc, setDesc] = useState({
    title: '',
    content: ''
  });
  const { data, fetch } = useRequest();

  useEffect(() => {
    fetch(getOption(user, 'membership_desc'));
  }, []);

  useEffect(() => {
    if (!data) return null;

    setDesc(JSON.parse(data.optionValue));
  }, [data]);

  return (
    <Card>
      <Card.Body>
        <h3 className="text-center">{desc.title}</h3>
        <div
          className="fs--1 ps-2 mb-0"
          dangerouslySetInnerHTML={createMarkup(desc.content)}
        ></div>
      </Card.Body>
    </Card>
  );
};
export default FAQ;
