import React, { useContext, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { getBalance, getMembershipLevel } from 'app/api/MemberService';
import BalanceModal from './BalanceModal';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import CountUp from 'react-countup';

const Balance = () => {
  const { response, sendData } = useRequest();
  const { user } = useContext(UserContext);
  const {
    response: membershipLevelResponse,
    sendData: membershipLevelSendData
  } = useRequest();

  useEffect(() => {
    sendResponse();
    sendResponseMembershipLevel();
  }, []);

  function sendResponse() {
    sendData(getBalance(user.token, user.id));
  }

  const balance = useMemo(() => {
    return response?.data?.balance ? response.data.balance : '';
  }, [response]);

  function sendResponseMembershipLevel() {
    membershipLevelSendData(getMembershipLevel(user.token, user.id));
  }

  const membershipLevel = useMemo(() => {
    return membershipLevelResponse?.data ? membershipLevelResponse.data : {};
  }, [membershipLevelResponse]);

  return (
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2">Balance</h6>
      </Card.Header>
      <Card.Body className="d-flex align-items-end justify-content-between">
        <div>
          <h2 className="mb-1 fw-normal lh-1">
            <CountUp
              end={balance}
              duration={1}
              separator=","
              className="text-monospace fs-4"
            />
            <span style={{ color: '#50AF95' }}> USDT</span>
          </h2>
          <BalanceModal membershipLevel={membershipLevel} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Balance;
