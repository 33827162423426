export const getMediaType = mime => {
  const map = {
    'image/jpeg': 'image',
    'image/png': 'image',
    'image/webp': 'image',
    'application/pdf': 'pdf',
    'video/webm': 'video',
    'video/mp4': 'video',
    'audio/mpeg': 'audio',
    'application/x-msdownload': 'link',
    link: 'link'
  };

  return map[mime] || 'unknown';
};
