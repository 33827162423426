import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ContentsContext, SlotsContext } from './CommitmentsContext';
import axios from 'axios';
import { postContent } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';

const SlotsProvider = ({ children }) => {
  const initSlots = [
    { id: 0, data: null },
    { id: 1, data: null },
    { id: 2, data: null },
    { id: 3, data: null },
    { id: 4, data: null },
    { id: 5, data: null },
    { id: 6, data: null },
    { id: 7, data: null },
    { id: 8, data: null },
    { id: 9, data: null },
    { id: 10, data: null },
    { id: 11, data: null }
  ];

  const { user } = useContext(UserContext);
  const { load, loaded, contents } = useContext(ContentsContext);

  const [data, setData] = useState([]);
  const [isInit, setIsInit] = useState(false);
  const [slots, setSlots] = useState(initSlots);

  //////////////////////////////////////////////////
  const [isViewer, setIsViewer] = useState(false);
  const [current, setCurrent] = useState(null);
  const [counter, setCounter] = useState(0);
  //////////////////////////////////////////////////

  useEffect(() => {
    if (!loaded) return;

    let newData = data;
    contents.forEach(content => {
      newData.push(content);
    });

    setData(newData);
    setIsInit(true);
  }, [loaded]);

  useEffect(() => {
    if (!isInit) return;

    setSlots(
      slots.map(slot => {
        return { ...slot, data: pop() };
      })
    );
  }, [isInit]);

  const watched = currentSlot => {
    axios
      .request(postContent(user.token, user.id, currentSlot.data.id))
      .then(() => {
        setSlots(
          slots.map(slot => {
            if (currentSlot.id === slot.id) {
              return { ...slot, data: pop() };
            }

            return { ...slot };
          })
        );

        incCounter();
        more();
      });
  };

  const next = currentSlot => {
    let nextSlotId = currentSlot.id + 1 < slots.length ? currentSlot.id + 1 : 0;
    setCurrent(slots[nextSlotId]);
  };

  const pop = () => {
    const el = data.shift();
    setData(data);

    return el;
  };

  const more = () => {
    data.length < 12 && load();
  };

  const incCounter = () => {
    setCounter(counter + 1);
  };

  const value = {
    slots,
    data,
    counter,
    watched,
    next,

    isViewer,
    setIsViewer,
    current,
    setCurrent
  };

  return (
    <SlotsContext.Provider value={value}>{children}</SlotsContext.Provider>
  );
};

SlotsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SlotsProvider;
