import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/options';

export const getOption = (user, optionName) => {
  return {
    url: `${endpointUrl}/${optionName}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  };
};
