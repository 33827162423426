import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import useInterval from 'react-useinterval';

const ViewerImage = ({ content, handleEnd, isPlay }) => {
  let [count, setCount] = useState(0);

  const runCount = step => {
    if (count < 10) {
      isPlay && setCount(count + step);
    } else {
      setCount(0);
      handleEnd();
    }
  };

  useInterval(runCount, 1000, 1);

  return (
    <>
      <Image
        src={content.filename}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </>
  );
};

ViewerImage.propTypes = {
  content: PropTypes.object,
  controls: PropTypes.object,
  handleEnd: PropTypes.func,
  isPlay: PropTypes.bool
};

export default ViewerImage;
