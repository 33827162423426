import React from 'react';
import { Row } from 'react-bootstrap';
import bg1 from 'assets/img/generic/bg-1.jpg';
import Section from 'components/common/Section';

const Hero = () => {
  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      position="center bottom"
      overlay
    >
      <Row
        className="justify-content-center align-items-center"
        style={{ paddingBottom: '52px' }}
      ></Row>
    </Section>
  );
};

export default Hero;
