import React from 'react';
import Hero from './Hero';
import NavbarStandard from '../../common/NavbarStandard';
import Cta from './Cta';
import Footer from '../../common/Footer';
import Investment from './Investment';
import '../../assets/css/custom.css';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      <Investment />
      <Cta />
      <Footer />
    </>
  );
};

export default Landing;
