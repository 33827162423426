import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import 'app/helpers/initFA';
import { useNavigate } from 'react-router-dom';

const Earn = () => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-center">
        <FontAwesomeIcon
          className="text-primary"
          icon="arrow-circle-down"
          size="3x"
        ></FontAwesomeIcon>
      </Col>
      <Col xs={12} className="d-flex justify-content-center">
        <Button
          onClick={() => {
            navigate('/dashboard/commitments');
          }}
          variant="outline-primary"
          className="mt-3"
          style={{ minWidth: '120px' }}
        >
          Earn
        </Button>
      </Col>
    </Row>
  );
};

export default Earn;
