import React from 'react';
import NavbarTop from 'app/pages/membership/components/nav/NavbarTop';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <div className="container">
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Outlet />
        </div>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;
