import React from 'react';
import { Helmet } from 'react-helmet';
import LoginLayout from './LoginLayout';

export default function Login() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Login | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <LoginLayout />
    </>
  );
}
