import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';

const ReferralChartItem = ({ item }) => {
  const { name, color, value } = item;

  return (
    <>
      <Col xs={8} lg={6} xl={8} xxl={4}>
        <p className="mb-1 fw-semi-bold fs--2">
          <FontAwesomeIcon
            icon="circle"
            className="me-2"
            style={{ color: color }}
          />
          {name}
        </p>
      </Col>
      <Col xs={4} lg={6} xl={4} xxl={8} style={{ marginTop: '-5px' }}>
        <span className="fs--2">{value}</span>
      </Col>
    </>
  );
};

ReferralChartItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default ReferralChartItem;
