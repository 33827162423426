import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/options/public';

export const getOption = optionName => {
  return {
    url: `${endpointUrl}/${optionName}`,
    method: 'GET'
  };
};
