import React from 'react';
import { Card } from 'react-bootstrap';

const PdfPlayer = ({ media }) => {
  return (
    <Card className="overflow-hidden">
      <Card.Header>{media.title}</Card.Header>
      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <iframe height="100%" title="pdf-player" src={media.path} />
      </Card.Body>
    </Card>
  );
};

export default PdfPlayer;
