import { createContext } from 'react';

const UserContext = createContext({
  user: null,
  setUser: null,
  removeUser: null,
  setStatus: null
});

export default UserContext;
