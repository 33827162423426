import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Card, Col, Row } from 'react-bootstrap';

import PageHeader from 'components/common/PageHeader';
import useApi from 'app/hooks/useApi';
import { seminarList } from 'app/api/SeminarConfig';

const SingleItem = seminar => {
  return (
    <Col lg="4" className="ask-analytics mb-3">
      <Link
        to={`/dashboard/seminars/${seminar.id}`}
        className="text-decoration-none"
      >
        <div className="border border-1 border-300 rounded-2 ask-analytics-item position-relative p-3 h-100">
          <h2 className="fs-1">{seminar.title}</h2>
          <span>{seminar.descriptionBlock}</span>
        </div>
      </Link>
    </Col>
  );
};

export default function SeminarList() {
  const { data: seminars } = useApi(seminarList());

  if (!seminars) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Seminars</title>
      </Helmet>

      <PageHeader title="Seminars" description="" className="mb-3" />

      <Card>
        <Card.Body className="pb-0">
          <Row>{seminars.map(seminar => SingleItem(seminar))}</Row>
        </Card.Body>
      </Card>
    </>
  );
}
