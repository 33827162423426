import React from 'react';
import SimpleBarReact from 'simplebar-react';

import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';

import useApi from 'app/hooks/useApi';
import { viewSeminarSectionMedia } from 'app/api/SeminarConfig';
import PlaylistItem from './SeminarPlaylistItem';
import { useParams } from 'react-router-dom';
import 'app/assets/css/custom.css';

const SeminarPlaylist = () => {
  const { sectionId } = useParams();
  const {
    data: items,
    isLoading,
    isLoaded
  } = useApi(viewSeminarSectionMedia(sectionId));
  let content = '';

  if (isLoading) {
    content = <div className="p-3">Loading...</div>;
  }

  if (isLoaded) {
    content = items.map(item => <PlaylistItem media={item} key={item.id} />);
  }

  return (
    <Card className="overflow-hidden h-100">
      <FalconCardHeader title="Playlist" titleTag="h6" light />
      <SimpleBarReact className="playlist-bar">
        <Card.Body className="py-0 px-0">{content}</Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

export default SeminarPlaylist;
