import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';

import Divider from 'components/common/Divider';
import ReferralsChart from './ReferralsChart';
import ReferralProgress from './ReferralsProgress';
import ReferralRequestContext from '../../contexts/ReferralRequestContext';

const MyReferralsStats = () => {
  const { referrals } = useContext(ReferralRequestContext);

  return (
    <Card className="h-100 mb-3">
      <Card.Body>
        <h6 className="mt-1 mb-4">My Referrals Stats</h6>
        <ReferralProgress />
        {referrals.length > 0 && (
          <>
            <Divider className="my-5">Stats</Divider>
            <ReferralsChart />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default MyReferralsStats;
