import FalconCloseButton from 'components/common/FalconCloseButton';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MembershipGold from './contents/MembershipGold';
import MembershipSilver from './contents/MembershipSilver';
import MembershipBronze from './contents/MembershipBronze';
import MembershipCash from './contents/MembershipCash';

const InfoModal = ({ membershipLevel }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const renderBody = membership => {
    switch (membership.slug) {
      case 'bronze':
        return <MembershipBronze membership={membership} />;
      case 'silver':
        return <MembershipSilver membership={membership} />;
      case 'gold':
        return <MembershipGold membership={membership} />;
      case 'cash':
        return <MembershipCash membership={membership} />;
    }
  };

  return (
    <>
      <Button
        variant="link"
        className="p-0 fs--1 fw-medium"
        onClick={handleShow}
      >
        How to earn tokens?
      </Button>

      <Modal show={show} onHide={handleClose} size="xl" keyboard={true}>
        <Modal.Header>
          <Modal.Title>How To Earn Tokens?</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>{renderBody(membershipLevel)}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

InfoModal.propTypes = {
  membershipLevel: PropTypes.object
};

export default InfoModal;
