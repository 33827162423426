import React, { useContext, useState } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg-1.jpg';
import Section from 'components/common/Section';
import AppContext from 'context/Context';
import dashboardLightWebp from '../../assets/images/landing/dashboard-light.webp';
import dashboardDarkWebp from '../../assets/images/landing/dashboard-dark.webp';
import dashboardLightJpg from '../../assets/images/landing/dashboard-light.jpg';
import dashboardDarkJpg from '../../assets/images/landing/dashboard-dark.jpg';
import FalconCloseButton from 'components/common/FalconCloseButton';

const Hero = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Section
        className="py-0 overflow-hidden light"
        image={bg1}
        position="center bottom"
        overlay
      >
        <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <Col md={11} lg={8} xl={4} className="pb-7 pb-xl-9 text-center">
            <Button
              variant="outline-danger"
              className="mb-4 fs-1 border-2 rounded-pill"
              onClick={handleShow}
            >
              Try for FREE
            </Button>
            <h1 className="text-white fw-light">
              Get
              <Typed
                strings={[
                  'Access',
                  'Knowledge',
                  'Rewards',
                  'Crypto',
                  'Minechain'
                ]}
                typeSpeed={40}
                backSpeed={50}
                className="fw-bold ps-2"
                loop
              />
            </h1>
            <Button
              as={Link}
              to="/register"
              variant="outline-light"
              size="lg"
              className="border-2 rounded-pill mt-4 fs-0 py-2"
            >
              Start your own Chain
              <FontAwesomeIcon
                icon="play"
                transform="shrink-6 down-1 right-5"
              />
            </Button>
          </Col>
          <Col
            xl={{ span: 7, offset: 1 }}
            className="align-self-end mt-4 mt-xl-0"
          >
            <Link to="/" className="img-landing-dash-banner">
              <picture>
                <source
                  srcSet={isDark ? dashboardDarkWebp : dashboardLightWebp}
                  type="image/webp"
                ></source>
                <img
                  className="img-fluid"
                  src={isDark ? dashboardDarkJpg : dashboardLightJpg}
                  alt=""
                />
              </picture>
            </Link>
          </Col>
        </Row>
      </Section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Try Bronze Membership for FREE</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          For your free account please use promotion code <b>LimitedMax10</b>.
          Enter this into transaction number, select todays date and Submit.
          <br />
          <br />
          <i className="fs--1">Maximum 10 USDT withdrawal limit *</i>
        </Modal.Body>
        <Modal.Footer>
          <Button as={Link} to="/register" variant="link">
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Hero;
