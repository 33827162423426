import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import corner1 from 'assets/img/illustrations/corner-1.png';
import Background from 'components/common/Background';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { getOption } from 'app/api/OptionsService';

function toHTML(content) {
  return { __html: content };
}

const TodaysNotification = () => {
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const [content, setContent] = useState();

  useEffect(() => {
    if (response?.data) {
      setContent(response.data.optionValue);
    }
  }, [response]);

  useEffect(() => {
    sendData(getOption(user, 'dash_todays_notification'));
  }, []);

  return (
    <Card className="overflow-hidden h-100">
      <Background image={corner1} className="p-card bg-card" />
      <Card.Body className="position-relative">
        {content ? (
          <div dangerouslySetInnerHTML={toHTML(content)} />
        ) : (
          <span className="text-500">No content yet</span>
        )}
      </Card.Body>
    </Card>
  );
};

TodaysNotification.propTypes = {};

export default TodaysNotification;
