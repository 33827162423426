import UserContext from 'app/context/UserContext';
import React, { useContext, useEffect, useMemo } from 'react';
import Cards from './components/cards/Cards';
import Helmet from 'react-helmet';
import useRequest from 'app/hooks/useRequest';
import { getMember } from 'app/api/MemberService';
import Pending from './components/Pending';
import Activated from './components/Activated';

const Membership = () => {
  const { user, setStatus } = useContext(UserContext);
  const { response, sendData } = useRequest();

  useEffect(() => {
    const interval = setInterval(() => {
      if (user.status !== 'pending') {
        return null;
      }

      fetchMember();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useMemo(
    function () {
      if (response && response?.data?.status !== user.status) {
        setStatus(response.data.status);
      }

      return null;
    },
    [response]
  );

  function fetchMember() {
    const config = getMember(user.token, user.id);
    sendData(config);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Membership | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      {(() => {
        switch (user.status) {
          case 'inactive':
            return <Cards />;
          case 'pending':
            return <Pending />;
          case 'active':
            return <Activated />;
        }
      })()}
    </>
  );
};

export default Membership;
