import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';

const StatusBadge = ({ status }) => {
  const pickBackground = status => {
    switch (status) {
      case 'not verified':
        return 'secondary';
      case 'inactive':
        return 'secondary';
      case 'pending':
        return 'warning';
      case 'active':
        return 'success';
      default:
        return 'secondary';
    }
  };

  return (
    <SoftBadge bg={pickBackground(status)} className="me-2">
      {status}
    </SoftBadge>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string
};

export default StatusBadge;
