import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertError = ({ errors, type, className }) => {
  return errors[type].map(item => (
    <Alert className={className} key={item.msg} variant="danger">
      {item.msg}
    </Alert>
  ));
};

export default AlertError;
