import { useState } from 'react';

const useModalReferral = () => {
  const [show, setShow] = useState(false);
  const toogle = () => setShow(!show);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return {
    show,
    toogle,
    handleClose,
    handleShow
  };
};

export default useModalReferral;
