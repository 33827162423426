import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import { getToday } from 'app/helpers/date';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { getTransactions } from 'app/api/MemberService';
import { SlotsContext } from '../context/CommitmentsContext';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getHours = () => {
  let hours = [];
  for (let i = 1; i <= 24; i++) {
    hours.push(`${i}:00`);
  }

  return hours;
};

const getOptions = data => ({
  color: getColor('100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('100'),
    borderColor: getColor('100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params => {
      const { value } = params[0];
      return `${value} USDT`;
    },
    transitionDuration: 0
  },
  xAxis: {
    type: 'category',
    data: getHours(),
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('400'),
      formatter: value => `${value}`,
      margin: 15
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: { show: false },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      data,
      lineStyle: { color: getColor('primary') },
      itemStyle: {
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: 10, left: 10, bottom: 0, top: 10, containLabel: true }
});

const DailyRewards = () => {
  const { user } = useContext(UserContext);
  const { counter } = useContext(SlotsContext);
  const { data, sendData } = useRequest();

  const [rewards, setRewards] = useState([]);
  const [rankedData, setRankedData] = useState([]);

  const updateData = () => {
    sendData(getTransactions(user.token, user.id, 0));
  };

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    if (data && data.length) {
      setRewards(data);
    }
  }, [data]);

  useEffect(() => {
    updateData();
  }, [counter]);

  useEffect(() => {
    if (!rewards.length) return null;

    const today = getToday();
    let intervals = [];
    for (let i = 0; i < 24; i++) {
      let buf = new Date(today.setHours(i, 0, 0, 0));
      let start = new Date(buf);
      let end = new Date(buf.setHours(i + 1, 0, 0, 0));

      intervals.push({
        label: start.getHours(),
        start: start,
        end: end,
        total: 0
      });
    }

    intervals = intervals.map(interval => {
      let total = rewards.reduce((acc, reward) => {
        let rewardDate = new Date(reward.createdAt);

        if (rewardDate >= interval.start && rewardDate <= interval.end) {
          acc += reward.amount;
        }

        return acc;
      }, 0);

      return { ...interval, total: total };
    });

    setRankedData(intervals);
  }, [rewards]);

  const chartData = rankedData.map(rank => {
    return rank.total;
  });

  const total = chartData.reduce((acc, rank) => {
    return (acc += rank);
  }, 0);

  return (
    <Card className="h-100">
      <FalconCardHeader
        title={`Daily Rewards ${total} USDT`}
        titleTag="h6"
        className="pb-0"
      />
      <Card.Body>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions(chartData)}
          style={{ height: '180px' }}
        />
      </Card.Body>
    </Card>
  );
};

export default DailyRewards;
