import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { resetPassword } from 'app/api/MemberService';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const [errors, setErrors] = useState({
    password: [],
    confirmPassword: [],
    general: []
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setErrors({
      password: [],
      confirmPassword: [],
      general: []
    });
    setLoading(true);

    axios
      .request(
        resetPassword(
          searchParams.get('hash'),
          formData.password,
          formData.confirmPassword
        )
      )
      .then(() => {
        toast.success('Login with your new password', {
          theme: 'colored'
        });
      })
      .catch(error => {
        const errors = {
          password: [],
          confirmPassword: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          isInvalid={errors.password.length}
        />
        <FeedbackError errors={errors} type="password" />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
          isInvalid={errors.confirmPassword.length}
        />
        <FeedbackError errors={errors} type="confirmPassword" />
      </Form.Group>

      <Form.Group className="mb-3">
        {!loading ? (
          <Button
            type="submit"
            className="w-100"
            disabled={!formData.password || !formData.confirmPassword}
          >
            Set password
          </Button>
        ) : (
          <Button variant="primary" className="w-100" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}
      </Form.Group>

      <AlertError errors={errors} type="general" />

      <Link className="fs--1 text-600" to="/login">
        Go to login
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
