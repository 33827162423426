import React from 'react';

const Content = () => {
  return (
    <>
      <h1>Minechain Community Platform Terms and Conditions</h1>

      <p>
        <b>IMPORTANT DISCLAIMERS AND RISK WARNING:</b>
      </p>

      <p>
        The content on this platform may include information, views and opinions
        posted by third parties. Some of these third parties may also be
        remunerated by us, based on the popularity of their posts. At all times,
        however, please note that: (a) such content is presented to you on an
        “as is” basis for general information purposes only, without
        representation or warranty of any kind; (b) such content, views,
        comments and/or opinions posted on the platform belong to these third
        party posters, and do not purport to reflect the views, comments or
        opinions of Minechain; and (c) correspondingly, the posting of such
        content by third parties on our platform is not intended to be and shall
        not be construed as (nor will you represent the same to any other third
        parties as) an endorsement by Minechain of the views or opinions stated,
        or the reliabillity or accuracy of the information specified therein.
      </p>

      <p>
        We shall therefore not be liable or responsible for any errors or
        omissions, or for the results obtained from your use of such
        information. Where such content includes links to third-party sources,
        please also note that such links and the contents stated therein are
        also not under our control. We shall likewise not be responsible for the
        reliability and accuracy of such third- party sites and their contents.
        You are solely responsible for your own decisions in this regard, and we
        do not assume any duty of care, responsibility or liability for any
        losses that you may suffer.
      </p>

      <p>
        Please also note that: (a) Not all Minechain products and services (if
        any are referred to) are available in your region; and (b) Digital asset
        prices can be volatile. The value of your investment may go down or up
        and you may not get back the amount invested. You are solely responsible
        for your investment decisions and Minechain is not liable for any losses
        you may incur. The content on this platform shall not be construed as
        financial advice. (c) Minechain may change, updated or remove all or any
        of the rewards received or have pending on your account for users views,
        posts and referrals. Membership cost for access to the platform can also
        be lost, removed and limited at any time by Minechain.
      </p>

      <h3>
        <strong>A. INTRODUCTION</strong>
      </h3>

      <p>
        1. These terms, including the Platform Guidelines as set forth below
        (collectively, the “Terms”) relating to access to and use of Minechain
        Community Platform (“Platform”), is entered into between you, as user of
        the Platform (“you”, “your” or “User”) and relevant Minechain Operator
        as defined in the Minechain Term Of Use (“Minechain”, “we”, our” or
        “us”). For clarity, these Terms apply to any and all persons who access
        and use the Platform, including (without limitation) users who are
        permitted by us to post content on the Platform (in whichever language),
        and users who only access the Platform to view, like or re-post content
        on the Platform. Further, if you use the Platform on behalf of a
        company, organization or other entity, then “you” includes you and that
        entity and you represent and warrant that (a) you are an authorized
        representative of the entity with the authority to bind the entity to
        these Terms and (b) you agree to these Terms on the entity’s behalf.
      </p>

      <p>
        2. You may post (if permitted by us to do so), view, like and/or re-post
        content (“Platform Content”) available on the Platform. Your use and
        access of Platform is conditioned upon your acceptance of these Terms
        (as may be amended from time to time). BY ACCESSING OR OTHERWISE USING
        THE PLATFORM IN ANY WAY (INCLUDING, WITHOUTLIMITATION POSTING CONTENT IF
        PERMITTED BY US TO DO SO), YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND
        ACCEPTED THE PROVISIONS OF THESE TERMS (AS MAY BE AMENDED FROM TIME TO
        TIME), AND ANY OTHER APPLICABLE TERMS AND CONDITIONS WHICH MAY BE DEEMED
        INCORPORATED HEREIN.
      </p>

      <h3>
        <strong>B. POLICIES BY INCORPORATION</strong>
      </h3>

      <p>
        3. You also agree to be bound by all policies referenced and
        incorporated by reference herein, including, but not limited to the
        following (collectively, the “Policies”): Minechain Term Of Use, the
        Community Guidelines as set forth below, and any other terms and
        conditions (including restrictions, if any), that may be notified to you
        by us from time to time in relation to your access to and use of the
        Platform.
      </p>

      <p>
        4. Please carefully read each Policy and the Terms. All references to
        the Terms hereinafter will mean the Terms and the Policies, taken
        together, unless expressly stated otherwise. If you do not agree to be
        bound by the Terms or to comply with our Policies, do not use or access
        the Platform.
      </p>

      <p>
        5. We are constantly improving our services, including the Platform, and
        periodically we will need to revise or amend the Terms or the Policies
        to reflect those improvements. Accordingly, we reserve the right at our
        sole and absolute discretion to amend the Terms, and the Policies, at
        any time for any reason without liability. It is your sole
        responsibility to review these Terms and Policies from time to time to
        view any such changes. Continuing to access or use the Platform will
        constitute your acceptance of any revised or amended Terms or Policies.
        If you do not agree to any revisions or amendments to the Terms or the
        Policies, you should stop using the Platform. For clarity, references to
        use of the Platform in these Terms, will include the use of this
        Platform to post content (if you are permitted by us to do so).
      </p>

      <h3>
        <strong>C. LICENCE</strong>
      </h3>

      <p>
        6. Unless otherwise stated, MineChain and/or its licensors own the
        intellectual property rights for all material on MineChain. All
        intellectual property rights are reserved. You may access this from
        MineChain for your own personal use subjected to restrictions set in
        these terms and conditions.
      </p>

      <p>
        You must not:
        <ul>
          <li>Republish material from MineChain</li>
          <li>Sell, rent or sub-license material from MineChain</li>
          <li>Reproduce, duplicate or copy material from MineChain</li>
          <li>Redistribute content from MineChain</li>
        </ul>
      </p>

      <p>
        7. Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        MineChain does not filter, edit, publish or review Comments prior to
        their presence on the website. Comments do not reflect the views and
        opinions of MineChain,its agents and/or affiliates. Comments reflect the
        views and opinionsof the person who post their views and opinions. To
        the extent permitted by applicable laws, MineChain shall not be liable
        for the Comments or for any liability, damages or expenses caused and/or
        suffered as a result of any use of and/or posting of and/or appearance
        of the Comments on this website.
      </p>

      <p>
        MineChain reserves the right to monitor all Comments and to remove any
        Comments which can be considered inappropriate, offensive or causes
        breach of these Terms and Conditions.
      </p>

      <p>You warrant and represent that:</p>

      <ul>
        <li>
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </li>
        <li>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </li>
      </ul>

      <p>
        You hereby grant MineChain a non-exclusive license to use, reproduce,
        edit and authorize others to use, reproduce and edit any of your
        Comments in any and all forms, formats or media.
      </p>

      <h3>
        <strong>D. COMPLIANCE WITH LAWS</strong>
      </h3>

      <p>
        8. You agree to comply with all applicable laws, rules and regulations
        regarding the User Content and your use of the Platform. You represent
        and warrant that you have all such licences, registrations, and
        approvals to post User Content on the Platform (if applicable).
      </p>

      <h3>
        <strong>E. CONDUCT</strong>
      </h3>

      <p>
        9. You agree that: (a) you are solely responsible for your conduct while
        accessing or using the Platform (including when posting content on the
        Platform, viewing, liking and/or re- posting content from the Platform
        to other platforms); (b) you will comply with any other terms and
        conditions (including restrictions, if any), of which you may be
        notified from time to time (the terms of which shall also be deemed
        incorporated into these Terms), in relation to your access to and use of
        the Platform; and (d) if we have permitted you to post content on the
        Platform, you will not share such access rights with and permit any
        other person (not permitted by us) to post content on the Platform under
        your name.
      </p>

      <p>
        10. All users of the Platform (whether permitted by us to post content
        on the Platform or not) must comply with these Terms, the Community
        Guidelines and the Policies and any other requirements as may be
        notified by us from time to time. As such a user, you also agree in
        particular that you will not:
      </p>

      <p>
        a. create, upload, transmit, distribute, or store any content that
        prohibited by us (as may be notified to you from time to time) or
        content that is inaccurate, unlawful, infringing, defamatory, obscene,
        pornographic, invasive of privacy or publicity rights, harassing,
        threatening, abusive, inflammatory, or otherwise objectionable;
      </p>

      <p>
        b. impersonate any person or entity; falsely claim an affiliation with
        any person or entity or perform any other similar fraudulent activity;
      </p>

      <p>c. send junk mail or spam to users of the Platform;</p>

      <p>
        d. defame, harass, abuse, threaten, or defraud users of the Platform, or
        collect or attempt to collect, personal information about users or third
        parties without their consent;
      </p>

      <p>
        e. reverse engineer, decompile, disassemble, or otherwise attempt to
        discover the source code of the Platform or any part thereof;
      </p>

      <p>
        f. interfere with or damage the operation of the Platform by any means,
        including uploading or otherwise disseminating viruses, adware, spyware,
        worms, or other malicious code;
      </p>

      <p>
        g. attempt to indicate in any manner, without our prior written
        permission, that we have endorsed you, your views or comments or
        opinions, or any products or services mentioned by you for any purpose;
      </p>

      <p>
        h. represent yourself as giving financial advice or instructions to
        viewers or followers on how to trade;
      </p>

      <p>
        i. conduct yourself in a manner that may cause damage or harm to, or
        discredit, our or our brand’s reputation, or that may result in adverse
        publicity to us or our brand;
      </p>

      <p>
        j. Engage in any behaviors that: (i) can mislead others or disrupt the
        order of the Minechain community, such as batch registration, buying and
        selling accounts, generating false comments, likes, votes or influence
        manually or through the use of a click farm program or machine; or (ii)
        seriously endanger the legitimate rights and interests of others and the
        Minechain community generally.
      </p>

      <p>
        k. use Platform for any illegal purpose, or in violation of any
        applicable local, state, national, or international law or regulation,
        including without limitation laws governing intellectual property and
        other proprietary rights, data protection, and privacy;
      </p>

      <p>
        l. Share, post or endorse any false or misleading information, or
        information that you know cannot be verified or substantiated;
      </p>

      <p>
        m. Make any false or fraudulent claims or statements, including but not
        limited to false or misleading statements about historical market
        performance (e.g. exaggerating or fabricating information/data, etc);
      </p>

      <p>
        n. Post any fraudulent promotion, marketing information or advertising,
        or content that is objectionable in our view, for the purpose of
        defrauding or misleading others, or as a means of seeking improper
        profits.
      </p>

      <p>o. Post information with the intent of manipulating the market;</p>

      <p>
        p. Publish potentially dangerous content or using third-party websites
        to forge jump links, such as phishing sites, trojans, virus sites, etc.;
      </p>

      <p>
        q. Provide or induce other users to engage in illegal or unethical
        activities; and
      </p>

      <p>
        r. Force, induce or hire other users to follow an account, click on a
        link, share information, or to like, share or comment on any posts or
        article.
      </p>

      <p>
        11. As a user permitted to post content on the Platform, you also
        additionally agree that unless approved and authorized by us in advance,
        you will not: (a) post or share any referral link of any competitor
        exchange in your content; (b) refer to, target or seek to make any
        promotions directed at users in any one particular or specific country;
        and (c) post any QR codes on the Platform. For clarity, marketing is
        permitted at our discretion, provided there is no reference to it being
        targeted at any one particular country or country’s residents. If a post
        is sponsored, you shall expressly state so. We reserve the right to
        reject, take down, or amend posts to ensure compliance with legal and
        regulatory requirements.
      </p>

      <h3>
        <strong>G. EXCLUSION OF LIABILITY</strong>
      </h3>

      <p>
        12. Minechain takes no responsibility and assumes no liability for any
        User Content or your access to and use of the Platform, or for any loss
        or damage resulting therefrom, nor is Minechain liable for any mistakes,
        defamation, slander, libel, omissions, falsehoods, obscenity,
        pornography, or profanity you may encounter when using (or after you
        have used) the Platform. Your use of the Platform (including for the
        purpose of posting your content, if permitted by us to do so) is at your
        own risk. In addition, these rules do not create any private right of
        action on the part of any third party or any reasonable expectation that
        the Platform will not contain any content that is prohibited by such
        rules. For clarity, you also agree that while we will implement
        reasonable measures to ensure that User Content made available by or to
        you through the Platform are not subject to unauthorized copying, use or
        redistribution by other third parties, or that they comply with the
        requirements as set out in these Terms, the Community Guidelines and the
        Policies, we do not guarantee the same, and we shall not be liable for
        any losses or damage that may be caused to you, in the event of such an
        eventuality. You also acknowledge and agree to all the relevant
        disclaimers and risk warnings, insofar as they relate to the Platform
        and your access or usage of the same.
      </p>

      <p>
        13. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
        MINECHAIN BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, OR CONSEQUENTIAL
        DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO
        LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA, WHETHER IN AN ACTION IN
        CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE,
        ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO
        USE THE PLATFORM INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR
        RESULTING FROM RELIANCE ON ANY INFORMATION OBTAINED FROM MINECHAIN OR
        THE PLATFORM, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS,
        DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN
        OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT
        RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION,
        OR UNAUTHORIZED ACCESS TO THE PLATFORM OR BINANCE’s RECORDS, PROGRAMS,
        OR SERVICES.
      </p>

      <h3>
        <strong>H. COPYRIGHT POLICY</strong>
      </h3>

      <p>
        14. Minechain respects the intellectual property of others. If you are
        the copyright owner or agent thereof and believe that content posted on
        the Platform infringes upon your copyright, please submit the following
        information:
      </p>

      <p>a. Identification of the copyright that is claimed to be infringed;</p>

      <p>
        b. Identification of the allegedly infringing material that is requested
        to be removed, including a description of where it is located;
      </p>

      <p>
        c. Information for us to contact you, such email address and telephone
        number;
      </p>

      <p>
        d. Explanation and supporting documents to support your claim that the
        infringing use is not authorized by the copyright owners, its agent or
        the law;
      </p>

      <p>
        e. A statement that the information provided is accurate and that you
        are the copyright owner or the authorized person to act on behalf of the
        copyright owner.
      </p>

      <h3>
        <strong>I. TRADEMARKS</strong>
      </h3>

      <p>
        15. MINECHAIN, the MINECHAIN logos, and any other product or service
        name, logo, or slogan used by Minechain (including but not limited to
        those relating to the Platform), may not be used in whole or in part in
        connection with any product or service that is not Minechain’s, in any
        manner that is likely to cause confusion among customers, or in any
        manner that disparages or discredits Minechain, without our prior
        written permission.
      </p>

      <h3>
        <strong>
          J. TERMINATION, SUSPENSION AND RESTRICTION OF ACCESS OR USAGE RIGHTS &
          TAKE DOWN OF CONTENT POSTED OR PROPOSED TO BE POSTED
        </strong>
      </h3>

      <p>
        16. Our rights under this clause are in addition and without prejudice
        to any other rights that we may have under the Minehchain Terms Of Use,
        or any other Policies and terms that may be applicable between us. We
        have the right to immediately terminate, suspend or restrict your access
        and/or usage rights to the Platform (whether in whole or in part), and
        to edit, delete or take down any User Content (or part thereof) that you
        may have submitted or posted (or are intending to submit or post) on the
        Platform at any time in our sole and absolute discretion, and for any
        duration as we deem appropriate, with or without cause or reason. Any
        such decision by us shall be final and non-appealable.
      </p>

      <p>
        In addition, we shall also have the right to immediately terminate,
        suspend or restrict your access and/or usage rights to the Platform
        (whether in whole or in part), and to edit, delete or take down any User
        Content (or part thereof) that you may have submitted or posted (or are
        intending to submit or post) on the Platform, if we have grounds to
        believe that:
      </p>

      <p>
        (i) you have engaged in (or are intending to engage in) unlawful acts
        using the Platform;
      </p>

      <p>
        (ii) your transmission of the User Content or your conduct generally
        infringes or violates any applicable laws and regulations, the terms and
        conditions applicable to you (which includes without limitation, these
        Terms and any other Policies), or any other requirements that Minechain
        may impose from time to time; or
      </p>

      <p>
        (iii) your User Content contains any material that may be inappropriate
        in our view, considering Minechain principles and core values.
      </p>

      <h3>
        <strong>K. GENERAL</strong>
      </h3>

      <p>
        17. You shall be bound by all the terms set out in the Minechain Terms
        Of Use and Minechain Privacy notice , which shall be read together with
        and supplemental to these Terms. Accordingly, the rights and protections
        afforded to us under the Minechain Terms of Use and Minechain Privacy
        Notice shall equally apply and be additional to the rights and
        protections afforded to us under these Terms.
      </p>

      <p>
        18. These Terms written in the English language may be translated into
        other languages. In the event of any conflict between this English
        language version and other translated versions of these Terms, this
        English language version shall prevail.
      </p>

      <h3>
        <strong>COMMUNITY GUIDELINES</strong>
      </h3>

      <p>
        Users of the Platform (whether or not you are a user that is permitted
        to post content on the Platform) must observe the following guidelines.
        These are common sense guidelines that apply to all users and users’
        activities associated with users’ access to and use of the Platform. We
        will update this document regularly based on the development of the
        Platform community and services, and such terms shall be deemed
        incorporated into the Minechain Feed Community Platform Terms and
        Conditions, and you agree to be bound by the same.
      </p>

      <p>
        In order to maintain the Platform community, we reserve the right to
        deactivate any account, or deny access to any user, at any time for any
        behavior that is determined to be inappropriate or harmful. Possible
        actions include: deleting content and revoking access and usage rights.
      </p>

      <p>
        Please read these Community Guidelines and all other terms that are
        applicable to you carefully. Continuing to access or use the Platform
        will constitute your acceptance of our Community Guidelines and the
        applicable terms. If you do not agree, you should stop accessing and/or
        using the Platform.
      </p>

      <p>(1) Illegal Behavior</p>

      <p>
        When using our Platform service, you must comply with all applicable
        local, national/regional and international laws. Any content or activity
        that focuses on illegal activities, encourages, provides, or abets
        others to participate in illegal activities is prohibited
      </p>

      <p>
        You also agree to immediately comply with any further instructions that
        we may issue to you from time to time, in relation to your use of the
        Platform (including but not limited to the type of content or the
        removal of links that you may have referred to as part of your content
        posted, or to be posted, on the Platform), insofar as such instructions
        are required by us to ensure that our Platform, and your use of the
        same, remains compliant with applicable legal and regulatory
        requirements.
      </p>

      <p>(2) Directing Behavior</p>

      <p>
        Excessively publicising and directing others towards Minechain’s direct
        and indirect competitors is prohibited.
      </p>

      <p>(3) Violence/Threats</p>

      <p>
        Content depicting, glorifying, admiring or supporting terrorist/violent
        extremist acts or behaviors is prohibited, including making threats or
        encouraging others to commit acts that may cause serious bodily harm or
        major property damage to the population.
      </p>

      <p>(4) Hateful Behavior and Harassment</p>

      <p>
        Hateful behavior and harassing language and behavior are not permitted.
        Hateful behavior refers to any content or activity that promotes or
        encourages discrimination, degradation, harassment, or violence against
        the protected characteristics of others. These characteristics include:
        race, ethnicity, color, caste, ancestry, immigration status, religion,
        sexual behavior, gender, gender identity, sexual orientation,
        disability, serious illness, and veteran status, etc. You must also
        refrain from conducting yourself in such a manner as to humiliate,
        abuse, attack, disrespect, harass, intimidate, threaten or slander other
        users of the Platform; or to provoke conflict and deliberately cause
        quarrels in the Minechain community or the Platform generally.
      </p>

      <p>(5) Protect your identity</p>

      <p>
        We want our users to feel safe when sharing content on the Platform
        which is why we encourage you to let us know if any contents on the
        Platform (or re-posted content from the Platform) violate your privacy
        or sense of safety.
      </p>

      <p>
        If someone posted your personal information without your knowledge
        (including in private or sensitive circumstances), ask the uploader to
        remove the content. If you can’t reach an agreement with the uploader,
        or if you are uncomfortable contacting them, you can request to have the
        content removed, giving a detailed explanation of the facts and
        description of the situation. The DPO team will assess the case.
      </p>

      <p>
        For content to be considered for removal an individual must be uniquely
        identifiable and that individual, or their legal representative, must
        submit the complaint. When assessing if an individual is uniquely
        identifiable, we consider the following factors:
      </p>

      <ul>
        <li>Image of voice.</li>
        <li>Full name</li>
        <li>Financial information</li>
        <li>Contact information</li>
        <li>Other personally identifiable information.</li>
      </ul>

      <p>(6) How you can protect your Privacy on the Platform</p>

      <ul>
        <li>
          Think carefully before you share your content on the Platform. This
          includes for example where you live, your financial information, and
          your contact details.
        </li>
        <li>
          Protect your account data, and do not share any of your passwords with
          others. Minechain employees will never ask you for passwords. Don’t be
          fooled if someone reaches out to you pretending to be from Minechain.
        </li>
        <li>
          Get permission first, before posting personal information of others.
        </li>
      </ul>

      <p>(7) Unauthorized Sharing of Personal Information</p>

      <p>
        Infringement of the privacy of others is prohibited. Sharing content
        that may reveal personal privacy of other person or private property
        without permission is prohibited. This includes but is not limited to:
      </p>

      <p>
        * Sharing personal identification information (such as real name,
        location, or ID).
      </p>
      <p>
        * Sharing restricted or protected personal social media profiles or any
        information in such profiles.
      </p>
      <p>
        * Sharing content that infringes on what can reasonably be understood to
        be private content belonging to others.
      </p>

      <p>(8) Impersonation</p>

      <p>
        Impersonating another individual's or organization's content or
        activities is prohibited. Any impersonation of Minechain personnel will
        not be tolerated. Such behavior will result in being banned.
      </p>

      <p>(9) Nudity, Pornography, and Other Obscene Content</p>

      <p>
        Nudity and other obscene content or activities such as pornography,
        sexual acts or intercourse, and sexual services are prohibited.
      </p>

      <p>
        Content or activities that threaten or promote sexual violence or abuse
        are strictly prohibited.
      </p>

      <p>Sexually suggestive content or activities are prohibited.</p>

      <p>(10) Extreme Violence, Blood, and Other Indecent Acts</p>

      <p>
        Excessive focus on extreme or unreasonable bloody and violent content is
        prohibited.
      </p>

      <p>(11) Intellectual Property</p>

      <p>
        Examples of content that cannot be shared on the Platform without the
        permission of the copyright owner or without legal permission include:
      </p>

      <p>* Content from other Platform content creators.</p>

      <p>* Pirated games or unauthorized private server content.</p>

      <p>
        * Content from other websites (subject to all applicable terms,
        incorporating links to 3rd party websites as part of your content posted
        on the Platform, is however permitted).
      </p>

      <p>* Movies, TV shows, or sports events.</p>

      <p>
        * Music that does not belong to you or that you do not have the right to
        share.
      </p>

      <p>(12) Independent Views and Opinions. No endorsement by Minechain</p>

      <p>
        In no circumstances should you attempt to indicate in any manner,
        without our prior written permission, that you are an employee of
        Minechain or that we have endorsed your views or opinions, or any
        products or services mentioned by you for any purpose.
      </p>
    </>
  );
};

export default Content;
