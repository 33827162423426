import React from 'react';
import { Helmet } from 'react-helmet';
import MailContent from 'app/pages/auth/confirmation/MailContent';

export default function СonfirmMail() {
  const email = localStorage.getItem('email_register');

  localStorage.removeItem('email_register');

  return (
    <div className="text-center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Confirm Mail | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>
      <MailContent email={email} />
    </div>
  );
}
