import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { register } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false,
    referralCode: searchParams.get('referral-id')
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [errors, setErrors] = useState({
    name: [],
    email: [],
    password: [],
    isAccepted: [],
    general: []
  });

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);
    setErrors({
      name: [],
      email: [],
      password: [],
      isAccepted: [],
      general: []
    });

    axios
      .request(register(formData))
      .then(() => {
        localStorage.setItem('email_register', formData.email);

        toast.success(`Successfully registered as ${formData.name}`, {
          theme: 'colored'
        });

        setTimeout(() => {
          navigate('/confirm-mail');
        }, 1000);
      })
      .catch(error => {
        const errors = {
          name: [],
          email: [],
          password: [],
          isAccepted: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFieldChange = e => {
    let val =
      e.target.name === 'email' ? e.target.value.trim() : e.target.value;

    setFormData({
      ...formData,
      [e.target.name]: val
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Name' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
          isInvalid={errors.name.length}
        />
        <FeedbackError errors={errors} type="name" />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
          isInvalid={errors.email.length}
        />
        <FeedbackError errors={errors} type="email" />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
            isInvalid={errors.password.length}
          />
          <FeedbackError errors={errors} type="password" />
        </Form.Group>

        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the{' '}
            <Link to="/terms-and-conditions" target="_blank">
              terms and privacy policy
            </Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <AlertError errors={errors} type="isAccepted" />

      <Form.Group className="mb-4">
        {!loading ? (
          <Button
            className="w-100"
            type="submit"
            disabled={
              !formData.name ||
              !formData.email ||
              !formData.password ||
              !formData.confirmPassword ||
              !formData.isAccepted
            }
          >
            Register
          </Button>
        ) : (
          <Button variant="primary" className="w-100" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}
      </Form.Group>

      <AlertError errors={errors} type="general" />
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
