import React from 'react';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ media }) => {
  return (
    <Card className="overflow-hidden">
      <Card.Header>{media.title}</Card.Header>
      <Card.Body className="py-0 px-0 ratio ratio-16x9">
        <ReactPlayer
          width="100%"
          height={'100%'}
          url={media.path}
          controls={true}
          playsinline={true}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
          onContextMenu={e => e.preventDefault()}
        />
      </Card.Body>
    </Card>
  );
};

export default VideoPlayer;
