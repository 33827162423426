import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import UserContext from 'app/context/UserContext';
import { remove } from 'app/api/MemberService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const DangerZone = () => {
  const navigate = useNavigate();
  const { user, removeUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const handleDeactivate = e => {
    e.preventDefault();

    if (
      !confirm(
        'Make sure you have withdrawn all fund before deactivating your account. All saved settings and balances will be lost.'
      )
    ) {
      return;
    }

    setLoading(true);

    axios
      .request(remove(user.token, user.id))
      .then(() => {
        toast.success(`Your account removed`, {
          theme: 'colored'
        });

        removeUser();
        navigate('/');
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card className="h-100">
      <FalconCardHeader title="Terminate Membership" className="bg-light" />
      <Card.Body>
        <h5 className="mb-1">Delete this account</h5>
        <p className="fs-0">
          Once you delete your account there is no going back, all saved data
          and account balance will be lost. Please be sure you have withdrawn
          all your funds before confirming.
        </p>
        {!loading ? (
          <Button
            onClick={handleDeactivate}
            to="#!"
            variant="falcon-danger"
            className="w-100"
          >
            Deactivate Account
          </Button>
        ) : (
          <Button className="w-100" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default DangerZone;
