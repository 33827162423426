import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import classNames from 'classnames';
import NavbarVertical from 'app/components/navbar/vertical/NavbarVertical';
import NavbarTop from 'app/components/navbar/top/NavbarTop';
import UserContext from 'app/context/UserContext';
import AppContext from 'context/Context';
// import useRequest from 'app/hooks/useRequest';
// import { check } from 'app/api/MemberService';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  // const { sendData } = useRequest();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);

  const { pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const interval = setInterval(() => {
  //     // fetchMember();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [pathname]);

  // function fetchMember() {
  //   sendData(check(user.token, user.id));
  // }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <ProductProvider>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
        </div>
      </ProductProvider>
    </div>
  );
};

export default DashboardLayout;
