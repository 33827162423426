import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Row } from 'react-bootstrap';

import 'app/helpers/initFA';
import Balance from './components/balance/Balance';
import Earn from './components/earn/Earn';
import Withdraw from './components/withdraw/Withdraw';
import UserContext from 'app/context/UserContext';
import useModalReferral from './hooks/useModalReferral';
import ModalReferralContext from './contexts/ModalReferralContext';
import EarnModal from './components/referralModal/EarnModal';
import useReferralRequest from './hooks/useReferralRequest';
import ReferralRequestContext from './contexts/ReferralRequestContext';
import MyReferralsList from './components/my-referrals-list/MyReferrals';
import MyReferralsStats from './components/my-referrals-stats/MyReferralsStats';
import WeeklyRewards from './components/weekly-rewards/WeeklyRewards';
import WeeklyCommitments from './components/weekly-commitments/WeeklyCommitments';
import TodaysAlerts from './components/todays-alerts/TodaysAlerts';
import DashboardProvider from './contexts/DashboardProvider';
import TodaysNotification from './components/todays-notification/TodaysNotification';

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const { show, toogle, handleClose, handleShow } = useModalReferral();
  const { referrals, isLoading } = useReferralRequest();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Dashboard | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <PageHeader
        title={'Welcome, ' + user.name}
        description=""
        className="mb-3"
        titleTag="h3"
      ></PageHeader>

      <DashboardProvider>
        <ModalReferralContext.Provider
          value={{
            show,
            toogle,
            handleClose,
            handleShow
          }}
        >
          <Row className="g-3 mb-3">
            <Col xl={6}>
              <Balance />
            </Col>
            <Col xl={6}>
              <Card className="h-100">
                <Card.Body>
                  <Row>
                    <Col className="border-end border-end-1  text-center">
                      <Earn />
                    </Col>
                    <Col className="text-center">
                      <Withdraw />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <ReferralRequestContext.Provider
            value={{
              referrals,
              isLoading
            }}
          >
            <Row className="g-3 mb-3">
              <Col xl="6">
                <MyReferralsStats />
              </Col>
              <Col xl="6">
                <MyReferralsList />
              </Col>
            </Row>
          </ReferralRequestContext.Provider>

          <EarnModal />
        </ModalReferralContext.Provider>

        <Row className="g-3 mb-3">
          <Col xl="6">
            <WeeklyRewards />
          </Col>
          <Col xl="6">
            <WeeklyCommitments />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col xl="6">
            <TodaysAlerts />
          </Col>
          <Col xl="6">
            <TodaysNotification />
          </Col>
        </Row>
      </DashboardProvider>
    </>
  );
};

export default Dashboard;
