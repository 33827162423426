import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl;

export const getBySectionId = sectionId => {
  return {
    url: `${endpointUrl}/comments/${sectionId}`,
    method: 'GET'
  };
};

export const create = data => {
  return {
    url: `${endpointUrl}/comments`,
    method: 'POST',
    data: { ...data }
  };
};
